import React from 'react';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Form from 'react-bootstrap/Form';
import Button from 'react-bootstrap/Button';
import {useState,useEffect} from 'react';
import Tab from 'react-bootstrap/Tab';
import Tabs from 'react-bootstrap/Tabs';
import Table from 'react-bootstrap/Table';
import $ from 'jquery';
import 'bootstrap/dist/css/bootstrap.min.css';
import swal from 'sweetalert';  
 
function Supply_mandays_2() {  
  const [rows, setRows] = useState(1); 
  const [data, setData] = useState([]); 
  const [date, setDate] = useState('');
  const [defaultOption, setDefaultOption] = useState(''); 
  const [isLoggedIn, setIsLoggedIn] = useState(true); // Assuming the user is initially logged in
  const [logoutTimer, setLogoutTimer] = useState(null);
  useEffect(()=>{
    const params = new URLSearchParams(window.location.search);
    const code = params.get('agencycode');
    const sitecode = params.get('sitecode');
    const name = params.get('agencyname');
    document.getElementById('agency').value=code;
    document.getElementById('sitecode').value=sitecode;
    document.getElementById('agencyname').value=name;
    $.ajax({
      type: "POST",
      url: "https://erp.smpinfra.in/smp/admin/agemain_mandays.php",
      data: {code:code},
      success: function(data) {
        try {
          var Datamain = JSON.parse(data);  
          setData(Datamain); 
          console.log(Datamain);
        } catch(error) {
          console.error("Error parsing JSON:");
        }
      } 
    });
  },[]);  
  
const logout=()=>{
    setIsLoggedIn(false);
		sessionStorage.removeItem("logstatus");
		window.location.reload();
	}
   const handleActivity = () => {
    // Reset the logout timer on user activity
    if (logoutTimer) {
      clearTimeout(logoutTimer);
    }
    const timer = setTimeout(logout, 15 * 60 * 1000); // Reset timer to 3 minutes
    setLogoutTimer(timer);
  };  
  const addnew = (event) =>{
    setRows(rows + 1);
  }
  const headers = []; 
  const checkIfLastDayOfMonth = (date) => {
    const nextDay = new Date(date);
    nextDay.setDate(date.getDate() + 1);
    return nextDay.getDate() === 1;
  };
  const dateset = (event) =>
    {
      var datemain = event.target.value; 
      setDate(datemain);
      const selectedDate = new Date(event.target.value);
      if (checkIfLastDayOfMonth(selectedDate)) {
        for (let i = 0; i < numberOfRows; i++) {
          document.getElementById("hide"+[i]).style.display = '';
        }
      }
    }
    const savedata = (event) =>
      {  
    var scode = $("#sitecode").val();
    var acode = $("#agency").val();
    var aname = $("#agencyname").val();
    var date1 = $("#dateset").val();
        for (let i = 0; i < rows; i++) { 
          var date = $("#date"+[i]).val();
          var fullhours = $("#fullhours"+[i]).val();
          var type1 = $("#type1"+[i]).val();
          var type2 = $("#type2"+[i]).val();
          var type3 = $("#type3"+[i]).val();
          var type4 = $("#type4"+[i]).val();
          var type5 = $("#type5"+[i]).val();
          var type6 = $("#type6"+[i]).val();
          if(type1!==''){
            var manday1 = $("#manday1"+[i]).val();
            var ot1 = $("#ot1"+[i]).val();
          }else{
            var manday1 = 0;
            var ot1 = 0;
          }
          if(type2!==''){
            var manday2 = $("#manday2"+[i]).val();
            var ot2 = $("#ot2"+[i]).val();
          } else{
            var manday2 = 0;
            var ot2 = 0;
          }
          if(type3!==''){
            var manday3 = $("#manday3"+[i]).val();
            var ot3 = $("#ot3"+[i]).val();
          } else{
            var manday3 = 0;
            var ot3 = 0;
          }
          if(type4!==''){
            var manday4 = $("#manday4"+[i]).val();
            var ot4 = $("#ot4"+[i]).val();
          } else{
            var manday4 = 0;
            var ot4 = 0;
          }
          if(type5!==''){
            var manday5 = $("#manday5"+[i]).val();
            var ot5 = $("#ot5"+[i]).val();
          } else{
            var manday5 = 0;
            var ot5 = 0;
          }
          if(type6!==''){
            var manday6 = $("#manday6"+[i]).val();
            var ot6 = $("#ot6"+[i]).val();
          } 
          else{
            var manday6 = 0;
            var ot6 = 0;
          }
          $.ajax({type:"POST",url:"https://erp.smpinfra.in/smp/admin/addot.php",data:{scode:scode,acode:acode,aname:aname,date:date,fullhours:fullhours,type1:type1,type2:type2,type3:type3,type4:type4,type5:type5,type6:type6,manday1:manday1,ot1:ot1,manday2:manday2,ot2:ot2,manday3:manday3,ot3:ot3,manday4:manday4,ot4:ot4,manday5:manday5,ot5:ot5,manday6:manday6,ot6:ot6},success(datanew){
            if(datanew){
              swal({title:"Successful!",text:"Added Successfully",icon:"success",buttons:false,timer:2000}).then(()=>{
                window.location.reload();
              });
              }  
            }}); 
        }
        for (let i = 0; i < numberOfRows; i++) {
          var name = $("#name"+[i]).val();
          var fullhours = $("#fullhoursextra"+[i]).val();
          var typeextra1 = $("#typeextra1"+[i]).val();
          var typeextra2 = $("#typeextra2"+[i]).val();
          var typeextra3 = $("#typeextra3"+[i]).val();
          var typeextra4 = $("#typeextra4"+[i]).val();
          var typeextra5 = $("#typeextra5"+[i]).val();
          var typeextra6 = $("#typeextra6"+[i]).val();
          if(typeextra1!==''){
            var mandayextra1 = $("#manday1"+[i]).val();
            var otextra1 = $("#otextra1"+[i]).val();
          }else{
            var mandayextra1 = 0;
            var otextra1 = 0;
          }
          if(typeextra2!==''){
            var mandayextra2 = $("#manday2"+[i]).val();
            var otextra2 = $("#otextra2"+[i]).val();
          } else{
            var mandayextra2 = 0;
            var otextra2 = 0;
          }
          if(typeextra3!==''){
            var mandayextra3 = $("#manday3"+[i]).val();
            var otextra3 = $("#otextra3"+[i]).val();
          } else{
            var mandayextra3 = 0;
            var otextra3 = 0;
          }
          if(typeextra4!==''){
            var mandayextra4 = $("#manday4"+[i]).val();
            var otextra4 = $("#otextra4"+[i]).val();
          } else{
            var mandayextra4 = 0;
            var otextra4 = 0;
          }
          if(typeextra5!==''){
            var mandayextra5 = $("#manday5"+[i]).val();
            var otextra5 = $("#otextra5"+[i]).val();
          } else{
            var mandayextra5 = 0;
            var otextra5 = 0;
          }
          if(typeextra6!==''){
            var mandayextra6 = $("#manday6"+[i]).val();
            var otextra6 = $("#otextra6"+[i]).val();
          } 
          else{
            var mandayextra6 = 0;
            var otextra6 = 0;
          }
          $.ajax({type:"POST",url:"https://erp.smpinfra.in/smp/admin/addotextra.php",data:{date1:date1,scode:scode,acode:acode,aname:aname,name:name,fullhours:fullhours,typeextra1:typeextra1,typeextra2:typeextra2,typeextra3:typeextra3,typeextra4:typeextra4,typeextra5:typeextra5,typeextra6:typeextra6,mandayextra1:mandayextra1,otextra1:otextra1,mandayextra2:mandayextra2,otextra2:otextra2,mandayextra3:mandayextra3,otextra3:otextra3,mandayextra4:mandayextra4,otextra4:otextra4,mandayextra5:mandayextra5,otextra5:otextra5,mandayextra6:mandayextra6,otextra6:otextra6},success(datanew){
            if(datanew){
              swal({title:"Successful!",text:"Added Successfully",icon:"success",buttons:false,timer:2000}).then(()=>{
                window.location.reload();
              });
              }  
            }}); 
        }
      }
      const numberOfRows = 4; 
return ( 
<div><Container style={{ color: "#565656", padding: '0', backgroundColor: 'white', maxWidth: '80rem', borderRadius: '15px', boxShadow: '0 1px 6px rgba(0, 0, 0, 0.12), 0 1px 4px rgba(0, 0, 0, 0.24)' }} fluid onMouseMove={handleActivity} onClick={handleActivity} onKeyDown={handleActivity}>
   <br />
   <h2 align="center">Mandays And OT</h2>
   <hr />
   <Row align="left" style={{ padding: '10px' }}> 
       <Col md={2}>
       <Form.Control id="sitecode" type="text" readOnly/> </Col>  
       <Col md={2}>
       <Form.Control id="agency" type="text" readOnly/> </Col>
       <Col md={5}>
       <Form.Control id="agencyname" type="text" readOnly/> </Col>
       <Col md={1}>Date:</Col>
       <Col md={2}>
       <Form.Control id="dateset" type="date" onChange={dateset}/> </Col>  
   </Row> 
  
   <Table striped bordered hover responsive> 
     <thead style={{ position:'sticky', zIndex: '1' }}> 
     <tr>
      <th>Sr No</th>
      <th>Date</th>
      <th>Full Hours</th>
     {data.length > 0 &&
            data.map((item2, index2) => {
              const headers = [];
              if (item2.type1 !== '-') {
                headers.push(<th key={`type1-${index2}`} colspan="2" style={{textAlign:'center'}}>{item2.type1}</th>);
              }
              if (item2.type2 !== '-') {
                headers.push(<th key={`type2-${index2}`} colspan="2" style={{textAlign:'center'}}>{item2.type2}</th>);
              }
              if (item2.type3 !== '-') {
                headers.push(<th key={`type3-${index2}`} colspan="2" style={{textAlign:'center'}}>{item2.type3}</th>);
              }
              if (item2.type4 !== '-') {
                headers.push(<th key={`type4-${index2}`} colspan="2" style={{textAlign:'center'}}>{item2.type4}</th>);
              }
              if (item2.type5 !== '-') {
                headers.push(<th key={`type5-${index2}`} colspan="2" style={{textAlign:'center'}}>{item2.type5}</th>);
              }
              if (item2.type6 !== '-') {
                headers.push(<th key={`type6-${index2}`} colspan="2" style={{textAlign:'center'}}>{item2.type6}</th>);
              }
              return headers;
            })}
            </tr>
            <tr>
              <th></th>
              <th></th>
              <th></th>
              {data.length > 0 &&
            data.map((item2, index2) => {
              const headers = [];
              if (item2.type1 !== '-') {
                headers.push(<th>Man Day</th>);
                headers.push(<th>OT Hours</th>);
              }
              if (item2.type2 !== '-') {
                headers.push(<th>Man Day</th>);
                headers.push(<th>OT Hours</th>);
              }
              if (item2.type3 !== '-') {
                headers.push(<th>Man Day</th>);
                headers.push(<th>OT Hours</th>);
              }
              if (item2.type4 !== '-') {
                headers.push(<th>Man Day</th>);
                headers.push(<th>OT Hours</th>);
              }
              if (item2.type5 !== '-') {
                headers.push(<th>Man Day</th>);
                headers.push(<th>OT Hours</th>);
              }
              if (item2.type6 !== '-') {
                headers.push(<th>Man Day</th>);
                headers.push(<th>OT Hours</th>);
              }
              return headers;
            })}
            </tr>
     </thead> {[...Array(rows)].map((_, index) => (
     <tbody key={index}>
     {data.length > 0 &&
          data.map((item, idx) => (
            <tr key={idx}>
              <td>{index + 1}</td>
              <td><Form.Control id={`date${index}`} type="text" value={date}/></td>
              <td><Form.Control id={`fullhours${index}`} type="number" /></td>
              {item.type1 !== '-' ? (
                <>
                  <td><Form.Control id={`type1${index}`} type="text" value={item.type1} hidden/>
                  <Form.Control id={`manday1${index}`} type="number" /></td>
                  <td><Form.Control id={`ot1${index}`} type="number" /></td>
                </>
              ):(<td><Form.Control id={`type1${index}`} type="text" value={item.type1} hidden/></td>)}
              {item.type2 !== '-' ? (
                <>
                  <td>
                  <Form.Control id={`type2${index}`} type="text" value={item.type2} hidden/>
                    <Form.Control id={`manday2${index}`} type="number" /></td>
                  <td><Form.Control id={`ot2${index}`} type="number" /></td>
                </>
              ):( <td>
                <Form.Control id={`type2${index}`} type="text" value={item.type2}/></td>)}
              {item.type3 !== '-' ? (
                <>
                  <td>
                  <Form.Control id={`type3${index}`} type="text" value={item.type3} hidden/>
                  <Form.Control id={`manday3${index}`} type="number" /></td>
                  <td><Form.Control id={`ot3${index}`} type="number" /></td>
                </>
              ):(<td>
                <Form.Control id={`type3${index}`} type="text" value={item.type3} hidden/></td>)}
              {item.type4 !== '-' ? (
                <> 
                  <td><Form.Control id={`type4${index}`} type="text" value={item.type4} hidden/><Form.Control id={`manday4${index}`} type="number" /></td>
                  <td><Form.Control id={`ot4${index}`} type="number" /></td>
                </>
              ):(<Form.Control id={`type4${index}`} type="text" value={item.type4} hidden/>)}
              {item.type5 !== '-' ? (
                <> 
                  <td> <Form.Control id={`type5${index}`} type="text" value={item.type5} hidden/><Form.Control id={`manday5${index}`} type="number" /></td>
                  <td><Form.Control id={`ot5${index}`} type="number" /></td>
                </>
              ):( <Form.Control id={`type5${index}`} type="text" value={item.type5} hidden/>)}
              {item.type6 !== '-' ? (
                <> 
                  <td> <Form.Control id={`type6${index}`} type="text" value={item.type6} hidden/><Form.Control id={`manday6${index}`} type="number" /></td>
                  <td><Form.Control id={`ot6${index}`} type="number" /></td>
                </>
              ):( <Form.Control id={`type6${index}`} type="text" value={item.type6} hidden/>)}
            </tr>
          ))} 
     </tbody> ))}  
     {[...Array(numberOfRows)].map((_, index4) => (
          <tbody key={index4} id={`hide${index4}`} style={{display:'none'}}>
            {data.length > 0 &&
          data.map((item2, idx2) => (
            <tr key={idx2}>
            <td></td>
            <td><Form.Control id={`name${index4}`} type="text"  /></td>
            <td><Form.Control id={`fullhoursextra${index4}`} type="text"  /></td> 
            {item2.type1 !== '-' ? (
                <>
                  <td><Form.Control id={`typeextra1${index4}`} type="text" value={item2.type1} hidden/>
                  <Form.Control id={`mandayextra1${index4}`} type="number" /></td>
                  <td><Form.Control id={`otextra1${index4}`} type="number" /></td>
                </>
              ):(<td><Form.Control id={`typeextra1${index4}`} type="text" value={item2.type1} hidden/></td>)}
               {item2.type2 !== '-' ? (
                <>
                  <td>
                  <Form.Control id={`typeextra2${index4}`} type="text" value={item2.type2} hidden/>
                    <Form.Control id={`mandayextra2${index4}`} type="number" /></td>
                  <td><Form.Control id={`otextra2${index4}`} type="number" /></td>
                </>
              ):( <td>
                <Form.Control id={`typeextra2${index4}`} type="text" value={item2.type2}/></td>)}
                {item2.type3 !== '-' ? (
                <>
                  <td>
                  <Form.Control id={`typeextra3${index4}`} type="text" value={item2.type3} hidden/>
                  <Form.Control id={`mandayextra3${index4}`} type="number" /></td>
                  <td><Form.Control id={`otextra3${index4}`} type="number" /></td>
                </>
              ):(<td>
                <Form.Control id={`typeextra3${index4}`} type="text" value={item2.type3} hidden/></td>)}
                {item2.type4 !== '-' ? (
                <> 
                  <td><Form.Control id={`typeextra4${index4}`} type="text" value={item2.type4} hidden/><Form.Control id={`mandayextra4${index4}`} type="number" /></td>
                  <td><Form.Control id={`otextra4${index4}`} type="number" /></td>
                </>
              ):(<Form.Control id={`typeextra4${index4}`} type="text" value={item2.type4} hidden/>)}
              {item2.type5 !== '-' ? (
                <> 
                  <td> <Form.Control id={`typeextra5${index4}`} type="text" value={item2.type5} hidden/><Form.Control id={`mandayextra5${index4}`} type="number" /></td>
                  <td><Form.Control id={`otextra5${index4}`} type="number" /></td>
                </>
              ):( <Form.Control id={`typeextra5${index4}`} type="text" value={item2.type5} hidden/>)}
              {item2.type6 !== '-' ? (
                <> 
                  <td> <Form.Control id={`typeextra6${index4}`} type="text" value={item2.type6} hidden/><Form.Control id={`mandayextra6${index4}`} type="number" /></td>
                  <td><Form.Control id={`otextra6${index4}`} type="number" /></td>
                </>
              ):( <Form.Control id={`typeextra6${index4}`} type="text" value={item2.type6} hidden/>)}
            </tr>))} </tbody>
            ))} 
       </Table> 

       <Button type="submit" variant="primary" style={{ backgroundColor: 'rgb(0, 189, 252)', width: '50%', height: "50px", borderRadius: "0px 0px 15px 15px", border: '1.5px solid white' }} onClick={addnew}>Add Record</Button> 
        <Button type="submit" variant="primary" style={{ backgroundColor: 'rgb(0, 189, 252)', width: '50%', height: "50px", borderRadius: "0px 0px 15px 15px", border: '1.5px solid white' }} onClick={savedata}>Save</Button> 
 </Container>
 </div>
);
}

export default Supply_mandays_2;