import React from 'react';
import {useState,useEffect} from 'react';
import Button from 'react-bootstrap/Button'; 
import Container from 'react-bootstrap/Container';
import Offcanvas from 'react-bootstrap/Offcanvas';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col'; 
import Form from 'react-bootstrap/Form';
import NavDropdown from 'react-bootstrap/NavDropdown'; 
import Accordion from 'react-bootstrap/Accordion';
import $ from 'jquery'; 
import swal from 'sweetalert';
import Home from './Home';
import Home1 from './Home1';
import Site from './Site';
import Agency from './Agency';
import Employee from './Employee';
import Agencysel from './Agencysel'; 
import Supply_mandays from './Supply_mandays';
import Supply_mandays_user from './Supply_mandays_user';
import Supply_mandays_2 from './Supply_mandays_2';
import Manday_report from './Manday_report';
import Manday_report_user from './Manday_report_user';
import User from './User';
import Site_agency_catagory_service_mapping from './Site_agency_catagory_service_mapping';
import Worker_attendance_sheet from './Worker_attendance_sheet';  
import Worker_attendance_sheet_user from './Worker_attendance_sheet_user';   
import Site_agency_catagory_service_mapping_user from './Site_agency_catagory_service_mapping_user' ;
import Priority from './Priority';
import Priority_user from './Priority_user';
import './view.css';  
import Nav from 'react-bootstrap/Nav';
import Navbar from 'react-bootstrap/Navbar';  
import logo from './Images/smplogo1.png';
import pf from './Images/profile-25.png';
import 'bootstrap/dist/css/bootstrap.min.css'; 
import { BrowserRouter,Routes,Route } from 'react-router-dom'; 
const Sidebar = () =>{
  const [isLoggedIn, setIsLoggedIn] = useState(true); // Assuming the user is initially logged in
  const [logoutTimer, setLogoutTimer] = useState(null);

  useEffect(() => { 
    // Start the logout timer when the component mounts or when the user is logged in
    if (isLoggedIn) {
      const timer = setTimeout(logout, 15 * 60 * 1000); // 3 minutes in milliseconds
      setLogoutTimer(timer);
    }

    // Clear the logout timer when the component unmounts or when the user logs out
    return () => {
      if (logoutTimer) {
        clearTimeout(logoutTimer);
      }
    };
  }, [isLoggedIn]); 
    const [show, setShow] = useState(false);
    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);
	const [vpw,setVpw] = useState($(window).width());
	const [vph,setVph] = useState($(window).height()); 
	$(window).on('resize',function(){
          setVpw($(window).width());
          setVph($(window).height());
	}); 
  const handleKeyPress = (event) => {
    if (event.key === 'Enter') {
      authenticate(event);
    }
  }; 
  const authenticate=(event)=>{   
    var uname = $('#username').val();
		var upass = $('#password').val(); 
		try{
		$.ajax({type:"POST",url:"https://erp.smpinfra.in/smp/admin/adminlogin.php",data:{uname:uname,upass:upass},success(data){
		try{
		var jsonLogin = JSON.parse(data);
		if(jsonLogin.length!==0){ 
				sessionStorage.setItem("logstatus","true");
         setIsLoggedIn(true);
				sessionStorage.setItem("userid",jsonLogin[0].userid);
				sessionStorage.setItem("username",jsonLogin[0].uname);
        sessionStorage.setItem("siteid",jsonLogin[0].usiteid);
        sessionStorage.setItem("sitename",jsonLogin[0].usite);
        sessionStorage.setItem("role",jsonLogin[0].role);
				window.location.reload(); 
		}
		}
		catch(e){
			console.log(e)
			swal({title:"Login Failed!",text:"There was an error while login",icon:"error",buttons:false,timer:1300});
		}
	}});
	}
	catch(e){
		alert(e);
	}
	}
  const logout=()=>{
    setIsLoggedIn(false);
		sessionStorage.removeItem("logstatus");
		window.location.reload();
	}
   const handleActivity = () => {
    // Reset the logout timer on user activity
    if (logoutTimer) {
      clearTimeout(logoutTimer);
    }
    const timer = setTimeout(logout, 15 * 60 * 1000); // Reset timer to 3 minutes
    setLogoutTimer(timer);
  };
  if(sessionStorage.getItem("logstatus")==="true"){
    if(sessionStorage.getItem("role")==="all project-master-user"){
    if(vpw < 500){
    return(
   <div onMouseMove={handleActivity} onClick={handleActivity} onKeyDown={handleActivity}>
  <BrowserRouter>
    <Navbar bg="light" style={{boxShadow: '0 1px 6px rgba(0, 0, 0, 0.12), 0 1px 4px rgba(0, 0, 0, 0.24)'}} variant="light">
    <Container>
      <Navbar.Brand onClick={handleShow}><img src={logo} height="50px" width="130px" alt="logo"/*style={{/*transform:/*"skew(-30deg)"}}*/ /></Navbar.Brand>
      <Nav className="ms-auto">
       <NavDropdown bg="dark" title={<img src={pf} height="30px" width="30px" alt="logo"/>} align="end">
   <NavDropdown.Item href="/" onClick={(event)=>logout()}>Logout</NavDropdown.Item>
   </NavDropdown>
      </Nav>
    </Container>
    </Navbar>
   <Container style={{paddingTop:vph/30,marginBottom:"150px",minHeight:vph,border:"4px solid #f2f6ff",borderTopWidth:"0px",borderBottomWidth:"0px"}}>
		<Routes>
				<Route path="/" element={<Home />}></Route>
                <Route path="/Home" element={<Home />}></Route>
                <Route path="/Site" element={<Site />}></Route>
                <Route path="/Agency" element={<Agency />}></Route> 
                <Route path="/Employee" element={<Employee />}></Route>
                <Route path="/User" element={<User />}></Route>  
                <Route path="/Agencysel" element={<Agencysel />}></Route>
                <Route path="/Supply_mandays" element={<Supply_mandays />}></Route>
                <Route path="/Supply_mandays_2" element={<Supply_mandays_2 />}></Route>
                <Route path="/Manday_report" element={<Manday_report />}></Route>
                <Route path="/Site_agency_catagory_service_mapping" element={<Site_agency_catagory_service_mapping />}></Route> 
                <Route path="/Priority" element={<Priority />}></Route>
                <Route path="/Worker_attendance_sheet" element={<Worker_attendance_sheet />}></Route>
               
                </Routes>  </Container>
                <Offcanvas style={{width:"60%"}} show={show} onHide={handleClose}>
        <Offcanvas.Header closeButton>
          <Offcanvas.Title>Menu</Offcanvas.Title>
        </Offcanvas.Header>
        <Offcanvas.Body>{
            <div>
            <div id="home" className="inActive"><a href="/home">Home</a></div><br/>
            <Accordion> 
        <Accordion.Item>
    <Accordion.Header className='acc'>Master</Accordion.Header>
      <Accordion.Body>
        <div id="home" className="inActive"><a href="/site">Project</a></div><br/>
            <div id="home" className="inActive"><a href="/agency">Contractor</a></div><br/>
    <div id="home" className="inActive"><a href="/employee">Employee</a></div><br/>
    <div id="home" className="inActive"><a href="/user">User</a></div><br/> <div id="home" className="inActive"><a href="/agencysel"> Select Contractor</a></div><br/>
        </Accordion.Body>
      </Accordion.Item>
    </Accordion>
    <br/>
    <Accordion> 
      <Accordion.Item>
        <Accordion.Header className='acc'>Labour Billing</Accordion.Header>
        <Accordion.Body> 
        <div id="home" className="inActive"><a href="/supply_mandays">Add Mandays And OT</a></div><br/> 
                     <div id="home" className="inActive"><a href="/manday_report">View Mandays And OT</a></div><br/> 
        </Accordion.Body>
      </Accordion.Item>
    </Accordion>
    <br/>
      <Accordion>
      <Accordion.Item>
      <Accordion.Header className='acc'>Attendance</Accordion.Header>
      <Accordion.Body>
      <div id="home" className="inActive"><a href="/site_agency_catagory_service_mapping">Add Attendance</a></div><br/>
      <div id="home" className="inActive"><a href="/worker_attendance_sheet">View Attendance</a></div><br/>  
    </Accordion.Body>
    </Accordion.Item>
    </Accordion>
      </div>
            }</Offcanvas.Body>
        </Offcanvas>
        </BrowserRouter></div>
        );
        }
        else {
            return( 
              <div onMouseMove={handleActivity} onClick={handleActivity} onKeyDown={handleActivity}>
                <BrowserRouter>  
                 <Navbar bg="light" style={{boxShadow: '0 1px 6px rgba(0, 0, 0, 0.12), 0 1px 4px rgba(0, 0, 0, 0.24)'}} variant="light">
    <Container>
      <Navbar.Brand onClick={handleShow}><img src={logo} height="50px" width="130px" /*style={{/*transform:/*"skew(-30deg)"}}*/ /></Navbar.Brand>
      <Nav className="ms-auto">
       <NavDropdown bg="dark" title={<img src={pf} height="30px" width="30px"/>} align="end">
   
   <NavDropdown.Item href="/" onClick={(event)=>logout()}>Logout</NavDropdown.Item>
   </NavDropdown>
      </Nav>
    </Container>
    </Navbar>        
            <Container fluid style={{backgroundColor:"#f2f6ff",marginLeft:"0px",marginRight:"0px"}}>
                <Row>
                <Col md={2} style={{padding:"0px"}}>
                <Container fluid style={{backgroundColor:'white',minHeight:vph,paddingTop:"20px"}}>
                {
                     <div>
                     <div id="home" className="inActive"><a href="/home">Home</a></div><br/>
     <Accordion> 
      <Accordion.Item>
        <Accordion.Header className='acc'>Master</Accordion.Header>
        <Accordion.Body>
        <div id="home" className="inActive"><a href="/site">Project</a></div><br/>
                     <div id="home" className="inActive"><a href="/agency">Contractor</a></div><br/>
    <div id="home" className="inActive"><a href="/employee">Employee</a></div><br/>
    <div id="home" className="inActive"><a href="/user">User</a></div><br/>
    <div id="home" className="inActive"><a href="/agencysel"> Select Contractor</a></div><br/>
        </Accordion.Body>
      </Accordion.Item>
    </Accordion>
    <br/>
    <Accordion> 
      <Accordion.Item>
        <Accordion.Header className='acc'>Labour Billing</Accordion.Header>
        <Accordion.Body> 
        <div id="home" className="inActive"><a href="/supply_mandays">Add Mandays And OT</a></div><br/> 
                     <div id="home" className="inActive"><a href="/manday_report">View Mandays And OT</a></div><br/> 
        </Accordion.Body>
      </Accordion.Item>
    </Accordion>
    <br/>
    <Accordion>
      <Accordion.Item>
      <Accordion.Header className='acc'>Attendance</Accordion.Header>
      <Accordion.Body>
      <div id="home" className="inActive"><a href="/site_agency_catagory_service_mapping">Add Attendance</a></div><br/>
      
      <div id="home" className="inActive"><a href="/worker_attendance_sheet">View Attendance</a></div><br/>  
    </Accordion.Body>
    </Accordion.Item>
    </Accordion>
        </div>
                 }</Container></Col>
                 <Col md={10} style={{height:"1100px",border:"4px solid rgb(0, 189, 252)",borderTopWidth:"0px",borderBottomWidth:"0px"}}>
		   <Container fluid style={{paddingTop:vph/10}}>
			<Routes>
				<Route path="/" element={<Home />}></Route>
                <Route path="/Home" element={<Home />}></Route>
                <Route path="/Site" element={<Site />}></Route>
                <Route path="/Agency" element={<Agency />}></Route>
                <Route path="/Agencysel" element={<Agencysel />}></Route>
                <Route path="/Supply_mandays" element={<Supply_mandays />}></Route>
                <Route path="/Supply_mandays_2" element={<Supply_mandays_2 />}></Route>
                <Route path="/Manday_report" element={<Manday_report />}></Route>
                <Route path="/Employee" element={<Employee />}></Route>
                <Route path="/User" element={<User />}></Route>  
                <Route path="/Priority" element={<Priority />}></Route>
                <Route path="/Site_agency_catagory_service_mapping" element={<Site_agency_catagory_service_mapping />}></Route>
                <Route path="/Worker_attendance_sheet" element={<Worker_attendance_sheet />}></Route>
             
                </Routes></Container>
		</Col>
		</Row>
		</Container>
                 </BrowserRouter> </div>
                );
        }
        
    }
  else if(sessionStorage.getItem("role")==="all project-master"){
    if(vpw < 500){
      return( 
        <div onMouseMove={handleActivity} onClick={handleActivity} onKeyDown={handleActivity}>
    <BrowserRouter>
      <Navbar bg="light" style={{boxShadow: '0 1px 6px rgba(0, 0, 0, 0.12), 0 1px 4px rgba(0, 0, 0, 0.24)'}} variant="light">
      <Container>
        <Navbar.Brand onClick={handleShow}><img src={logo} height="50px" width="130px" /*style={{/*transform:/*"skew(-30deg)"}}*/ /></Navbar.Brand>
        <Nav className="ms-auto">
         <NavDropdown bg="dark" title={<img src={pf} height="30px" width="30px"/>} align="end">
     
     <NavDropdown.Item href="/" onClick={(event)=>logout()}>Logout</NavDropdown.Item>
     </NavDropdown>
        </Nav>
      </Container>
      </Navbar>
     <Container style={{paddingTop:vph/30,marginBottom:"150px",minHeight:vph,border:"4px solid #f2f6ff",borderTopWidth:"0px",borderBottomWidth:"0px"}}>
      <Routes>  
          <Route path="/" element={<Home />}></Route>
                <Route path="/Home" element={<Home />}></Route>
                <Route path="/Site" element={<Site />}></Route>
                <Route path="/Agency" element={<Agency />}></Route>
                <Route path="/Employee" element={<Employee />}></Route>  
                <Route path="/Agencysel" element={<Agencysel />}></Route>
                <Route path="/Supply_mandays" element={<Supply_mandays />}></Route>
                <Route path="/Supply_mandays_2" element={<Supply_mandays_2 />}></Route>
                <Route path="/Manday_report" element={<Manday_report />}></Route>
                <Route path="/Priority" element={<Priority />}></Route>
                <Route path="/Site_agency_catagory_service_mapping" element={<Site_agency_catagory_service_mapping />}></Route>
                <Route path="/Worker_attendance_sheet" element={<Worker_attendance_sheet />}></Route>
                  </Routes>  </Container>
                  <Offcanvas style={{width:"60%"}} show={show} onHide={handleClose}>
          <Offcanvas.Header closeButton>
            <Offcanvas.Title>Menu</Offcanvas.Title>
          </Offcanvas.Header>
          <Offcanvas.Body>{
              <div> 
          <div id="home" className="inActive"><a href="/home">Home</a></div><br/>
     <Accordion> 
      <Accordion.Item>
        <Accordion.Header className='acc'>Master</Accordion.Header>
        <Accordion.Body>
        <div id="home" className="inActive"><a href="/site">Project</a></div><br/>
                     <div id="home" className="inActive"><a href="/agency">Contractor</a></div><br/>
    <div id="home" className="inActive"><a href="/employee">Employee</a></div><br/> 
    <div id="home" className="inActive"><a href="/agencysel"> Select Contractor</a></div><br/>
        </Accordion.Body>
      </Accordion.Item>
    </Accordion>
    <br/>
    <Accordion> 
      <Accordion.Item>
        <Accordion.Header className='acc'>Labour Billing</Accordion.Header>
        <Accordion.Body> 
        <div id="home" className="inActive"><a href="/supply_mandays">Add Mandays And OT</a></div><br/> 
                     <div id="home" className="inActive"><a href="/manday_report">View Mandays And OT</a></div><br/> 
        </Accordion.Body>
      </Accordion.Item>
    </Accordion>
    <br/>
    <Accordion>
      <Accordion.Item>
      <Accordion.Header className='acc'>Attendance</Accordion.Header>
      <Accordion.Body>
      <div id="home" className="inActive"><a href="/site_agency_catagory_service_mapping">Add Attendance</a></div><br/> 
      <div id="home" className="inActive"><a href="/worker_attendance_sheet">View Attendance</a></div><br/>  
    </Accordion.Body>
    </Accordion.Item>
    </Accordion>
        </div>
              }</Offcanvas.Body>
          </Offcanvas>
          </BrowserRouter></div>
          );
          }
          else {
              return( 
                <div onMouseMove={handleActivity} onClick={handleActivity} onKeyDown={handleActivity}>
                  <BrowserRouter>  
                   <Navbar bg="light" style={{boxShadow: '0 1px 6px rgba(0, 0, 0, 0.12), 0 1px 4px rgba(0, 0, 0, 0.24)'}} variant="light">
      <Container>
        <Navbar.Brand onClick={handleShow}><img src={logo} height="50px" width="130px" /*style={{/*transform:/*"skew(-30deg)"}}*/ /></Navbar.Brand>
        <Nav className="ms-auto">
         <NavDropdown bg="dark" title={<img src={pf} height="30px" width="30px"/>} align="end">
     
     <NavDropdown.Item href="/" onClick={(event)=>logout()}>Logout</NavDropdown.Item>
     </NavDropdown>
        </Nav>
      </Container>
      </Navbar>        
              <Container fluid style={{backgroundColor:"#f2f6ff",marginLeft:"0px",marginRight:"0px"}}>
                  <Row>
                  <Col md={2} style={{padding:"0px"}}>
                  <Container fluid style={{backgroundColor:'white',minHeight:vph,paddingTop:"20px"}}>
                  {
                       <div>
                              <div id="home" className="inActive"><a href="/home">Home</a></div><br/>
     <Accordion> 
      <Accordion.Item>
        <Accordion.Header className='acc'>Master</Accordion.Header>
        <Accordion.Body>
        <div id="home" className="inActive"><a href="/site">Project</a></div><br/>
                     <div id="home" className="inActive"><a href="/agency">Contractor</a></div><br/>
    <div id="home" className="inActive"><a href="/employee">Employee</a></div><br/> 
    <div id="home" className="inActive"><a href="/agencysel"> Select Contractor</a></div><br/>
        </Accordion.Body>
      </Accordion.Item>
    </Accordion>
    <br/>
    <Accordion> 
      <Accordion.Item>
        <Accordion.Header className='acc'>Labour Billing</Accordion.Header>
        <Accordion.Body> 
        <div id="home" className="inActive"><a href="/supply_mandays">Add Mandays And OT</a></div><br/> 
                     <div id="home" className="inActive"><a href="/manday_report">View Mandays And OT</a></div><br/> 
        </Accordion.Body>
      </Accordion.Item>
    </Accordion>
    <br/>
   
    <Accordion>
      <Accordion.Item>
      <Accordion.Header className='acc'>Attendance</Accordion.Header>
      <Accordion.Body>
      <div id="home" className="inActive"><a href="/site_agency_catagory_service_mapping">Add Attendance</a></div><br/> 
      <div id="home" className="inActive"><a href="/worker_attendance_sheet">View Attendance</a></div><br/>  
    </Accordion.Body>
    </Accordion.Item>
    </Accordion>
          </div>
                   }</Container></Col>
                   <Col md={10} style={{height:"1100px",border:"4px solid rgb(0, 189, 252)",borderTopWidth:"0px",borderBottomWidth:"0px"}}>
         <Container fluid style={{paddingTop:vph/10}}>
        <Routes> 
        <Route path="/" element={<Home />}></Route>
                <Route path="/Home" element={<Home />}></Route>
                <Route path="/Site" element={<Site />}></Route>
                <Route path="/Agency" element={<Agency />}></Route>
                <Route path="/Employee" element={<Employee />}></Route> 
                <Route path="/Agencysel" element={<Agencysel />}></Route>
                <Route path="/Supply_mandays" element={<Supply_mandays />}></Route>
                <Route path="/Supply_mandays_2" element={<Supply_mandays_2 />}></Route>
                <Route path="/Manday_report" element={<Manday_report />}></Route>
                <Route path="/Priority" element={<Priority />}></Route>
                <Route path="/Site_agency_catagory_service_mapping" element={<Site_agency_catagory_service_mapping />}></Route>
                <Route path="/Worker_attendance_sheet" element={<Worker_attendance_sheet />}></Route> 
                  </Routes></Container>
      </Col>
      </Row>
      </Container>
                   </BrowserRouter> </div>
                  );
          }
  }
  else if(sessionStorage.getItem("role")==="all project"){
    if(vpw < 500){
      return( 
        <div onMouseMove={handleActivity} onClick={handleActivity} onKeyDown={handleActivity}>
    <BrowserRouter>
      <Navbar bg="light" style={{boxShadow: '0 1px 6px rgba(0, 0, 0, 0.12), 0 1px 4px rgba(0, 0, 0, 0.24)'}} variant="light">
      <Container>
        <Navbar.Brand onClick={handleShow}><img src={logo} height="50px" width="130px" /*style={{/*transform:/*"skew(-30deg)"}}*/ /></Navbar.Brand>
        <Nav className="ms-auto">
         <NavDropdown bg="dark" title={<img src={pf} height="30px" width="30px"/>} align="end">
     
     <NavDropdown.Item href="/" onClick={(event)=>logout()}>Logout</NavDropdown.Item>
     </NavDropdown>
        </Nav>
      </Container>
      </Navbar>
     <Container style={{paddingTop:vph/30,marginBottom:"150px",minHeight:vph,border:"4px solid #f2f6ff",borderTopWidth:"0px",borderBottomWidth:"0px"}}>
      <Routes>  
          <Route path="/" element={<Home />}></Route>  
          <Route path="/Priority" element={<Priority />}></Route>
                <Route path="/Site_agency_catagory_service_mapping" element={<Site_agency_catagory_service_mapping />}></Route>
                <Route path="/Worker_attendance_sheet" element={<Worker_attendance_sheet />}></Route> 
                <Route path="/Supply_mandays" element={<Supply_mandays />}></Route>
                <Route path="/Supply_mandays_2" element={<Supply_mandays_2 />}></Route>
                <Route path="/Manday_report" element={<Manday_report />}></Route>
                  </Routes>  </Container>
                  <Offcanvas style={{width:"60%"}} show={show} onHide={handleClose}>
          <Offcanvas.Header closeButton>
            <Offcanvas.Title>Menu</Offcanvas.Title>
          </Offcanvas.Header>
          <Offcanvas.Body>{
              <div>  
                <Accordion> 
      <Accordion.Item>
        <Accordion.Header className='acc'>Labour Billing</Accordion.Header>
        <Accordion.Body> 
        <div id="home" className="inActive"><a href="/supply_mandays">Add Mandays And OT</a></div><br/> 
                     <div id="home" className="inActive"><a href="/manday_report">View Mandays And OT</a></div><br/> 
        </Accordion.Body>
      </Accordion.Item>
    </Accordion>
    <br/>
    <Accordion>
      <Accordion.Item>
      <Accordion.Header className='acc'>Attendance</Accordion.Header>
      <Accordion.Body>
      <div id="home" className="inActive"><a href="/site_agency_catagory_service_mapping">Add Attendance</a></div><br/> 
      <div id="home" className="inActive"><a href="/worker_attendance_sheet">View Attendance</a></div><br/>  
    </Accordion.Body>
    </Accordion.Item>
    </Accordion>
        </div>
              }</Offcanvas.Body>
          </Offcanvas>
          </BrowserRouter></div>
          );
          }
          else {
              return( 
                <div onMouseMove={handleActivity} onClick={handleActivity} onKeyDown={handleActivity}>
                  <BrowserRouter>  
                   <Navbar bg="light" style={{boxShadow: '0 1px 6px rgba(0, 0, 0, 0.12), 0 1px 4px rgba(0, 0, 0, 0.24)'}} variant="light">
      <Container>
        <Navbar.Brand onClick={handleShow}><img src={logo} height="50px" width="130px" /*style={{/*transform:/*"skew(-30deg)"}}*/ /></Navbar.Brand>
        <Nav className="ms-auto">
         <NavDropdown bg="dark" title={<img src={pf} height="30px" width="30px"/>} align="end">
     
     <NavDropdown.Item href="/" onClick={(event)=>logout()}>Logout</NavDropdown.Item>
     </NavDropdown>
        </Nav>
      </Container>
      </Navbar>        
              <Container fluid style={{backgroundColor:"#f2f6ff",marginLeft:"0px",marginRight:"0px"}}>
                  <Row>
                  <Col md={2} style={{padding:"0px"}}>
                  <Container fluid style={{backgroundColor:'white',minHeight:vph,paddingTop:"20px"}}>
                  {
                       <div> 
                        <Accordion> 
      <Accordion.Item>
        <Accordion.Header className='acc'>Labour Billing</Accordion.Header>
        <Accordion.Body> 
        <div id="home" className="inActive"><a href="/supply_mandays">Add Mandays And OT</a></div><br/> 
                     <div id="home" className="inActive"><a href="/manday_report">View Mandays And OT</a></div><br/> 
        </Accordion.Body>
      </Accordion.Item>
    </Accordion>
    <br/>
    <Accordion>
      <Accordion.Item>
      <Accordion.Header className='acc'>Attendance</Accordion.Header>
      <Accordion.Body>
      <div id="home" className="inActive"><a href="/site_agency_catagory_service_mapping">Add Attendance</a></div><br/> 
      <div id="home" className="inActive"><a href="/worker_attendance_sheet">View Attendance</a></div><br/>  
    </Accordion.Body>
    </Accordion.Item>
    </Accordion>
          </div>
                   }</Container></Col>
                   <Col md={10} style={{height:"1100px",border:"4px solid rgb(0, 189, 252)",borderTopWidth:"0px",borderBottomWidth:"0px"}}>
         <Container fluid style={{paddingTop:vph/10}}>
        <Routes> 
        <Route path="/" element={<Home/>}></Route> 
        <Route path="/Priority" element={<Priority />}></Route>
                <Route path="/Site_agency_catagory_service_mapping" element={<Site_agency_catagory_service_mapping />}></Route>
                <Route path="/Worker_attendance_sheet" element={<Worker_attendance_sheet />}></Route> 
                <Route path="/Supply_mandays" element={<Supply_mandays />}></Route>
                <Route path="/Supply_mandays_2" element={<Supply_mandays_2 />}></Route>
                <Route path="/Manday_report" element={<Manday_report />}></Route>
                  </Routes></Container>
      </Col>
      </Row>
      </Container>
                   </BrowserRouter> </div>
                  );
          }
  }
  else if(sessionStorage.getItem("role")==="project"){
    if(vpw < 500){
      return( 
        <div onMouseMove={handleActivity} onClick={handleActivity} onKeyDown={handleActivity}>
    <BrowserRouter>
      <Navbar bg="light" style={{boxShadow: '0 1px 6px rgba(0, 0, 0, 0.12), 0 1px 4px rgba(0, 0, 0, 0.24)'}} variant="light">
      <Container>
        <Navbar.Brand onClick={handleShow}><img src={logo} height="50px" width="130px" /*style={{/*transform:/*"skew(-30deg)"}}*/ /></Navbar.Brand>
        <Nav className="ms-auto">
         <NavDropdown bg="dark" title={<img src={pf} height="30px" width="30px"/>} align="end">
     
     <NavDropdown.Item href="/" onClick={(event)=>logout()}>Logout</NavDropdown.Item>
     </NavDropdown>
        </Nav>
      </Container>
      </Navbar>
     <Container style={{paddingTop:vph/30,marginBottom:"150px",minHeight:vph,border:"4px solid #f2f6ff",borderTopWidth:"0px",borderBottomWidth:"0px"}}>
      <Routes>  
          <Route path="/" element={<Home1 />}></Route> 
          <Route path="/Priority_user" element={<Priority_user />}></Route> 
                <Route path="/Site_agency_catagory_service_mapping_user" element={<Site_agency_catagory_service_mapping_user/>}></Route>
                <Route path="/Worker_attendance_sheet_user" element={<Worker_attendance_sheet_user />}></Route> 
                <Route path="/Supply_mandays_user" element={<Supply_mandays_user />}></Route>
                <Route path="/Supply_mandays_2" element={<Supply_mandays_2 />}></Route>
                <Route path="/Manday_report_user" element={<Manday_report_user />}></Route>
                  </Routes>  </Container>
                  <Offcanvas style={{width:"60%"}} show={show} onHide={handleClose}>
          <Offcanvas.Header closeButton>
            <Offcanvas.Title>Menu</Offcanvas.Title>
          </Offcanvas.Header>
          <Offcanvas.Body>{
              <div>  
                <Accordion> 
      <Accordion.Item>
        <Accordion.Header className='acc'>Labour Billing</Accordion.Header>
        <Accordion.Body> 
        <div id="home" className="inActive"><a href="/supply_mandays_user">Add Mandays And OT</a></div><br/> 
                     <div id="home" className="inActive"><a href="/manday_report_user">View Mandays And OT</a></div><br/> 
        </Accordion.Body>
      </Accordion.Item>
    </Accordion>
    <br/>
    <Accordion>
      <Accordion.Item>
      <Accordion.Header className='acc'>Attendance</Accordion.Header>
      <Accordion.Body>
      <div id="home" className="inActive"><a href="/site_agency_catagory_service_mapping_user">Add Attendance</a></div><br/> 
      <div id="home" className="inActive"><a href="/worker_attendance_sheet_user">View Attendance</a></div><br/>  
    </Accordion.Body>
    </Accordion.Item>
    </Accordion>
        </div>
              }</Offcanvas.Body>
          </Offcanvas>
          </BrowserRouter></div>
          );
          }
          else {
              return( 
                <div onMouseMove={handleActivity} onClick={handleActivity} onKeyDown={handleActivity}>
                  <BrowserRouter>  
                   <Navbar bg="light" style={{boxShadow: '0 1px 6px rgba(0, 0, 0, 0.12), 0 1px 4px rgba(0, 0, 0, 0.24)'}} variant="light">
      <Container>
        <Navbar.Brand onClick={handleShow}><img src={logo} height="50px" width="130px" /*style={{/*transform:/*"skew(-30deg)"}}*/ /></Navbar.Brand>
        <Nav className="ms-auto">
         <NavDropdown bg="dark" title={<img src={pf} height="30px" width="30px"/>} align="end">
     
     <NavDropdown.Item href="/" onClick={(event)=>logout()}>Logout</NavDropdown.Item>
     </NavDropdown>
        </Nav>
      </Container>
      </Navbar>        
              <Container fluid style={{backgroundColor:"#f2f6ff",marginLeft:"0px",marginRight:"0px"}}>
                  <Row>
                  <Col md={2} style={{padding:"0px"}}>
                  <Container fluid style={{backgroundColor:'white',minHeight:vph,paddingTop:"20px"}}>
                  {
                       <div> 
                        <Accordion> 
      <Accordion.Item>
        <Accordion.Header className='acc'>Labour Billing</Accordion.Header>
        <Accordion.Body> 
        <div id="home" className="inActive"><a href="/supply_mandays_user">Add Mandays And OT</a></div><br/> 
                     <div id="home" className="inActive"><a href="/manday_report_user">View Mandays And OT</a></div><br/> 
        </Accordion.Body>
      </Accordion.Item>
    </Accordion>
    <br/>
    <Accordion>
      <Accordion.Item>
      <Accordion.Header className='acc'>Attendance</Accordion.Header>
      <Accordion.Body>
      <div id="home" className="inActive"><a href="/site_agency_catagory_service_mapping_user">Add Attendance</a></div><br/> 
      <div id="home" className="inActive"><a href="/worker_attendance_sheet_user">View Attendance</a></div><br/>  
    </Accordion.Body>
    </Accordion.Item>
    </Accordion>
          </div>
                   }</Container></Col>
                   <Col md={10} style={{height:"1100px",border:"4px solid rgb(0, 189, 252)",borderTopWidth:"0px",borderBottomWidth:"0px"}}>
         <Container fluid style={{paddingTop:vph/10}}>
        <Routes> 
        <Route path="/" element={<Home1 />}></Route> 
        <Route path="/Priority_user" element={<Priority_user />}></Route>
                <Route path="/Site_agency_catagory_service_mapping_user" element={<Site_agency_catagory_service_mapping_user />}></Route>
                <Route path="/Worker_attendance_sheet_user" element={<Worker_attendance_sheet_user />}></Route> 
                <Route path="/Supply_mandays_user" element={<Supply_mandays_user />}></Route>
                <Route path="/Supply_mandays_2" element={<Supply_mandays_2 />}></Route>
                <Route path="/Manday_report_user" element={<Manday_report_user />}></Route>
                  </Routes></Container>
      </Col>
      </Row>
      </Container>
                   </BrowserRouter> </div>
                  );
          }
  }
  } 
   
    else{
      return( 
        <Container className="cnt" fluid>
        <Container style={{marginTop:vph/3.2,backgroundColor:'white',maxWidth:'30rem',borderRadius:'25px',color:'black',boxShadow: '0 1px 6px rgba(0, 0, 0, 0.12), 0 1px 4px rgba(0, 0, 0, 0.24)'}} fluid>
          <br/>
          <h1 className="display-6" align="center">Sign In</h1>
          <br/>
          <form id="f1" >
          <Row>
          <Col md={2}></Col>
          <Col md={2}>UserName:</Col><Col md={6} align="left"><Form.Control name="username" type="text" id="username" placeholder="Enter UseName" autoComplete="off" class="required"/></Col>
          </Row><br/>
          <Row>
          <Col md={2}></Col>
          <Col md={2}>Password:</Col><Col md={6} align="left"><Form.Control name="password" type="password" id="password" placeholder="Enter Password" class="required" onKeyPress={handleKeyPress}/> 
          </Col>
          <br/><br/><br/>
              <Button onClick={authenticate} name="login" style={{backgroundColor:'#0885d3',height:"50px",borderRadius:"0px 0px 25px 25px",border:'1.5px solid white'}}>Login</Button>
          </Row></form>
        </Container>
        </Container> 
        );
    }
  };
export default Sidebar;