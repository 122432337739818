import React from 'react';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Form from 'react-bootstrap/Form';
import Button from 'react-bootstrap/Button';
import {useState,useEffect} from 'react';
import Tab from 'react-bootstrap/Tab';
import Tabs from 'react-bootstrap/Tabs';
import Table from 'react-bootstrap/Table';
import $ from 'jquery';
import "bootstrap/dist/css/bootstrap.min.css";
import "bootstrap/dist/js/bootstrap.min.js"; 
import swal from 'sweetalert';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTrash,faEdit, faTrashRestoreAlt } from '@fortawesome/free-solid-svg-icons';


const Employee = () =>{
  const [data, setData] = useState([]);
  const [siteOptions, setSiteOptions] = useState([]); 
  const [query, setQuery] = useState('');
  const [results, setResults] = useState([]);
  const [isLoggedIn, setIsLoggedIn] = useState(true); // Assuming the user is initially logged in
  const [logoutTimer, setLogoutTimer] = useState(null);
  const handleInputChange = (event) => {
      setQuery(event.target.value);
      fetchSearchResults(event.target.value);
  };

  const fetchSearchResults = (searchQuery) => {
      fetch(`https://erp.smpinfra.in/smp/admin/filter_emp.php?query=${searchQuery}`)
          .then(response => response.json())
          .then(data => {
              setResults(data);
          })
          .catch(error => {
              console.error('Error fetching search results:', error);
          });
  };

  useEffect(()=>{ 
    $('document').ready(function(){
      var date = new Date();
  
      var day = date.getDate();
      var month = date.getMonth() + 1;
      var year = date.getFullYear();
      
      if (month < 10) month = "0" + month;
      if (day < 10) day = "0" + day;
      
      var today = year + "-" + month + "-" + day;    
      $("#employeedoj").attr("value", today);
      }); 
      $.ajax({
        type:"POST",
        url:"https://erp.smpinfra.in/smp/admin/site_sel.php",
        success: function(data){
        var jsonData = JSON.parse(data);
        var table = "<option value=''>Select Location</option><option value='ho'>HO</option>";
        for(let i=0;i<jsonData.length;i++){
            if(jsonData[i].status != 0){
            table+="<option value='"+jsonData[i].site_code+"'>"+jsonData[i].site_code+"</option>";
            }
            var options = jsonData.filter(item => item.status !== 0).map(item => ({
              value: item.site_code,
              label: item.site_code
            }));
            setSiteOptions(options);
        } 
        document.getElementById("employeelocation").innerHTML = table;   
        try{
        var qstring = window.location.search;
        var params = new URLSearchParams(qstring);
        var id = params.get('did');
        }
        catch(e){
            id = '';
        }
        $('#employeelocation').val(id);  
         // Attach change event listener to select element
       $('#employeelocation').change(function () {
        var selectedValue = $(this).val();
        console.log(selectedValue); // You can do whatever you want with the selected value here
      }); 
    }
  }); 
    try {
      $.ajax({type:"POST",url:"https://erp.smpinfra.in/smp/admin/select_emp_all.php" ,success(data){
        var jsonData = JSON.parse(data); 
        document.cookie = jsonData.length; 
        setData(jsonData);
      }});
    }
    catch (error) {
      console.error('Error fetching data:', error);
    }  
  }, []);
  const employee_add=(event)=>{ 
    var employeecode = $('#employeecode').val();
    var employeename = $('#employeename').val(); 
    var employeelocation = $('#employeelocation').val();
    var employeedesignation = $('#employeedesignation').val(); 
    var employeedepartment = $('#employeedepartment').val();
    var employeedob = $('#employeedob').val(); 
    var employeedoj = $('#employeedoj').val();
    var employeestatus = $('#employeestatus').val();
    var employeeremark = $('#employeeremark').val(); 
   // alert(employeecode);
   try{
    if(employeecode==null || employeecode==""){
      swal({title:"Error!",text:"Employee Code Can Not Be Blank!",icon:"error",buttons:false,timer:2000}).then(()=>{
        $('#employeecode').focus();
      });
    }
    else if(employeename==null || employeename==""){
      swal({title:"Error!",text:"Employee Name Can Not Be Blank!",icon:"error",buttons:false,timer:2000}).then(()=>{
        $('#employeename').focus();
      });
    }
    else if(employeelocation==null || employeelocation==""){
      swal({title:"Error!",text:"Employee Location Can Not Be Blank!",icon:"error",buttons:false,timer:2000}).then(()=>{
        $('#employeelocation').focus();
      });
    }
    else if(employeedesignation==null || employeedesignation==""){
      swal({title:"Error!",text:"Employee Designation Can Not Be Blank!",icon:"error",buttons:false,timer:2000}).then(()=>{
        $('#employeedesignation').focus();
      });
    }
    
    else if(employeedepartment==null || employeedepartment==""){
      swal({title:"Error!",text:"Employee Department Can Not Be Blank!",icon:"error",buttons:false,timer:2000}).then(()=>{
        $('#employeedepartment').focus();
      });
    }
    else if(employeedob==null || employeedob==""){
      swal({title:"Error!",text:"Employee Date Of Birth Can Not Be Blank!",icon:"error",buttons:false,timer:2000}).then(()=>{
        $('#employeedob').focus();
      });
    }
    else if(employeedoj==null || employeedoj==""){
      swal({title:"Error!",text:"Employee Date Of Joining Can Not Be Blank!",icon:"error",buttons:false,timer:2000}).then(()=>{
        $('#employeedoj').focus();
      });
    }
    else if(employeestatus==null || employeestatus==""){
      swal({title:"Error!",text:"Employee Status Birth Can Not Be Blank!",icon:"error",buttons:false,timer:2000}).then(()=>{
        $('#employeestatus').focus();
      });
    }
    /*else if(employeecontactno.match(/[6-9]+[0-9]{9}/)==null){
      swal({title:"Error",text:"Invalid Mobile Number!",icon:"error",buttons:false,timer:1300}).then(()=>{
    $('#employeecontactno').focus();
  });	
}*/
     else{$.ajax({type:"POST",url:"https://erp.smpinfra.in/smp/admin/employee_add.php",data:{employeecode:employeecode,employeename:employeename,employeelocation:employeelocation,employeedesignation:employeedesignation,employeedepartment:employeedepartment,employeedob:employeedob,employeedoj:employeedoj,employeestatus:employeestatus,employeeremark:employeeremark},success(data){
        if(data == 1){
          swal({title:"Successful!",text:"New Employee Added Successfully",icon:"success",buttons:false,timer:2000}).then(()=>{
            window.location.reload();
          });
          }
          else{
            swal({title:"Error!",text:"Employee Already Exists",icon:"error",buttons:false,timer:2000}).then(()=>{
              window.location.reload();
            });
          }
      }});}}
      catch(e){
        alert(e);
      }
  }
  const export_emp = (event) =>{
   window.location.href = "https://erp.smpinfra.in/smp/admin/sample_export_employee.php";
  }
  const [inputValues, setInputValues] = useState({}); 
  const handleinputchange = (e, index) => {
    const { name, value } = e.target;
    setInputValues(prevState => ({
      ...prevState,
      [index]: {
        ...prevState[index],
        [name]: value
      }
    }));
  };
  const exportmaster = (event) =>{
    window.location.href = "https://erp.smpinfra.in/smp/admin/export_emp_master.php";
   } 
   const logout=()=>{
    setIsLoggedIn(false);
    sessionStorage.removeItem("logstatus");
    window.location.reload();
  }
   const handleActivity = () => {
    // Reset the logout timer on user activity
    if (logoutTimer) {
      clearTimeout(logoutTimer);
    }
    const timer = setTimeout(logout, 15 * 60 * 1000); // Reset timer to 3 minutes
    setLogoutTimer(timer);
  };
    return ( 
        <Container style={{color: "#565656",padding:'0',backgroundColor:'white',maxWidth:'78rem',borderRadius:'15px',boxShadow: '0 1px 6px rgba(0, 0, 0, 0.12), 0 1px 4px rgba(0, 0, 0, 0.24)',marginTop:'-45px'}} fluid onMouseMove={handleActivity} onClick={handleActivity} onKeyDown={handleActivity}>  <br/>
                <h2 align="center">Add Employee</h2> <hr/>
                <Button type="submit" variant="primary" style={{ backgroundColor: 'rgb(0, 189, 252)', width: '50%', height: "50px", borderRadius: "0px 0px 15px 15px", border: '1.5px solid white' }} data-bs-toggle="modal" data-bs-target="#exampleModal">Import File</Button>    
         <div className="modal fade" id="exampleModal" tabIndex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
         <div className="modal-dialog"style={{maxWidth:'78rem'}}>
            <div className="modal-content">
               <div className="modal-header">
               <h5 className="modal-title text-primary" id="exampleModalLabel">Import File</h5>
               <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
               </div>
               <div className="modal-body"> 
               <form method="POST" action="https://erp.smpinfra.in/smp/admin/employeeimport.php" encType="multipart/form-data">
               <Row align="left">
              <Col md={6}>
                <Form.Control type="file" name="import_file" id="import_file"/>
                </Col>
                <Button type="submit" variant="primary" style={{ backgroundColor: 'rgb(0, 189, 252)', width: '15%', height: "50px", borderRadius: "0px 0px 15px 15px", border: '1.5px solid white' }}name="save_excel_data">Import File</Button> 
                <Button type="button" variant="primary" style={{ backgroundColor: 'rgb(0, 189, 252)', width: '18%', height: "50px", borderRadius: "0px 0px 15px 15px", border: '1.5px solid white' }} onClick={exportmaster}>Export Employee Master</Button>
                <Button type="button" variant="primary" style={{ backgroundColor: 'rgb(0, 189, 252)', width: '15%', height: "50px", borderRadius: "0px 0px 15px 15px", border: '1.5px solid white' }} onClick={export_emp}>Export Sample File</Button>
                </Row></form> <br/>  
               <Table striped bordered hover responsive>
                <thead>
                  <tr>
                    <th>Sr No.</th>
                    <th>Emp Code</th>
                    <th>Employee Name</th>
                    <th>Location</th>
                    <th>Designation</th>
                    <th>Department</th>
                    <th>DOB</th> 
                    <th>DOJ</th> 
                    <th>Status</th> 
                    <th>Remarks</th> 
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <td>1</td>
                    <td>10007</td>
                    <td>YOGESH P ADHVARYU</td>
                    <td>HO</td>
                    <td>Purchase Head</td>
                    <td>Purchase</td>
                    <td>21-10-1963</td>
                    <td>21-10-2004</td>
                    <td>Working</td>
                    <td>Abc...</td>
                  </tr>
                </tbody>
          </Table>
               </div>
               <div className="modal-footer">
                <p>Note : Date Must Be In DD-MM-YYYY Format&#160;&#160;</p>
               <button type="button" className="btn btn-warning" data-bs-dismiss="modal">Close</button>
               </div>
            </div>
         </div>
         </div>

                <Button type="submit" variant="primary" style={{ backgroundColor: 'rgb(0, 189, 252)', width: '50%', height: "50px", borderRadius: "0px 0px 15px 15px", border: '1.5px solid white' }}data-bs-toggle="modal" data-bs-target="#exampleModal1">Fill Form</Button>
                <div className="modal fade" id="exampleModal1" tabIndex="-1" aria-labelledby="exampleModal1Label" aria-hidden="true">
         <div className="modal-dialog"style={{maxWidth:'78rem'}}>
            <div className="modal-content">
               <div className="modal-header">
               <h5 className="modal-title text-primary" id="exampleModal1Label">Add New Employee</h5>
               <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
               </div>
               <div className="modal-body"> 
               <Row align="left" style={{padding:'10px'}}>
                <Col md={1}></Col><Col md={3} style={{verticalAlign:"sub"}}>Employee Code:</Col><Col md={6}>
                <Form.Control placeholder="Enter Employee Code" type="text" id="employeecode" name="employeecode" required />
                </Col>
                </Row>
                <Row align="left" style={{padding:'10px'}}>
                <Col md={1}></Col><Col md={3} style={{verticalAlign:"sub"}}>Employee Name:</Col><Col md={6}>
                <Form.Control placeholder="Enter New Employee Name" type="text" id="employeename" name="employeename" required />
                </Col>
                </Row>
                <Row align="left" style={{padding:'10px'}}>
                <Col md={1}></Col><Col md={3} style={{verticalAlign:"sub"}}>Employee Location:</Col><Col md={6}> <Form.Select id="employeelocation" name="employeelocation">  
			          </Form.Select>  
                </Col>
                </Row>
                <Row align="left" style={{padding:'10px'}}>
                <Col md={1}></Col><Col md={3} style={{verticalAlign:"sub"}}>Designation:</Col><Col md={6}>
                <Form.Control placeholder="Enter Employee Designation" type="text" id="employeedesignation" name="employeedesignation" required/>
                </Col>
                </Row>
                <Row align="left" style={{padding:'10px'}}>
                <Col md={1}></Col><Col md={3} style={{verticalAlign:"sub"}}>Employee Department:</Col><Col md={6}>
                <Form.Control placeholder="Enter Employee Department" type="text" id="employeedepartment" name="employeedepartment" required/>
                </Col>
                </Row>
                <Row align="left" style={{padding:'10px'}}>
                <Col md={1}></Col><Col md={3} style={{verticalAlign:"sub"}}>Employee DOB:</Col><Col md={6}>
                <Form.Control placeholder="Enter Employee DOB" type="date" id="employeedob" name="employeedob" required/></Col>
                </Row>
                <Row align="left" style={{padding:'10px'}}>
                <Col md={1}></Col><Col md={3} style={{verticalAlign:"sub"}}>Employee DOJ:</Col><Col md={6}>
                <Form.Control placeholder="Enter Employee DOJ" type="date" id="employeedoj" name="employeedoj" required/></Col>
                </Row>
                <Row align="left" style={{padding:'10px'}}>
                <Col md={1}></Col><Col md={3} style={{verticalAlign:"sub"}}>Employee Status:</Col><Col md={6}>
                <Form.Select id="employeestatus" name="employeestatus">  
                <option hidden>Select Status</option>
                <option value="Probation">Probation</option>
                <option value="Working">Working</option>
                <option value="Resign">Resign</option>
                <option value="Long Leave">Long Leave</option>
                <option value="Terminated">Terminated</option>
			          </Form.Select>
                </Col>
                </Row>
                <Row align="left" style={{padding:'10px'}}>
                <Col md={1}></Col><Col md={3} style={{verticalAlign:"sub"}}>Remark:</Col><Col md={6}>
                <Form.Control placeholder="Enter Employee Remark" type="text" id="employeeremark" name="employeeremark"/>
                </Col>
                </Row>
                <br/>
               <center> <Button type="submit" variant="primary" style={{backgroundColor:'rgb(0, 189, 252)',width:'50%',height:"50px",borderRadius:"0px 0px 15px 15px",border:'1.5px solid white'}} onClick={employee_add}>Add Employee</Button></center>
               </div>
               <div className="modal-footer"> 
               <button type="button" className="btn btn-warning" data-bs-dismiss="modal">Close</button>
               </div>
            </div>
         </div>
         </div>
                <br/><br/>
                <Row align="left" style={{padding:'10px'}}> <center>
                <Col md={11}>
                <Form.Control type="text"value={query}
                onChange={handleInputChange}
                placeholder="Search by name, code, date of birth, date of joining, location, designation or department" /></Col></center></Row><br/>
                 {results.length > 0 ? (
                <Table striped bordered hover responsive>
                <thead>
                  <tr>
                    <th></th>
                    <th></th>
                  <th>Sr No.</th>
                    <th>Emp Code</th>
                    <th>Employee Name</th>
                    <th>Location</th>
                    <th>Designation</th>
                    <th>Department</th>
                    <th>DOB</th> 
                    <th>DOJ</th> 
                    <th>Status</th> 
                    <th>Remarks</th>
                  </tr>
                </thead>
                {results.map((item, index) =>  ( <tbody><tr key={index}> 
                  <td>
                  <form action="https://erp.smpinfra.in/smp/admin/deleteemp.php" method="POST">
                  <Form.Control type="text" id="empid" name="empid" value={`${item.employee_id}`} hidden/> 
                <Button type="submit" variant="primary" style={{backgroundColor:'red',width:'100%',height:"50px",borderRadius:"0px 0px 15px 15px",border:'1.5px solid white'}} >  <FontAwesomeIcon icon={faTrash} /> </Button> 
                </form>
                </td> 
                <td>
                <Button type="submit" variant="primary" style={{backgroundColor:'rgb(0, 189, 252)',width:'100%',height:"50px",borderRadius:"0px 0px 15px 15px",border:'1.5px solid white'}} data-bs-toggle="modal" data-bs-target={`#search${item.emp_code}`}>  <FontAwesomeIcon icon={faEdit} /> </Button> 
                <div className="modal fade" id={`search${item.emp_code}`} tabIndex="-1" aria-labelledby={`searchLabel${item.emp_code}`} aria-hidden="true">
         <div className="modal-dialog"style={{maxWidth:'78rem'}}>
            <div className="modal-content">
               <div className="modal-header">
               <h5 className="modal-title text-primary" id="searchLabel">Update Employee</h5>
               <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
               </div><form action="https://erp.smpinfra.in/smp/admin/updateemp.php" method="POST">
               <div className="modal-body">   
               <Form.Control type="text" id="empid" name="empid" value={`${item.employee_id}`} hidden /> 
               <Row align="left" style={{padding:'10px'}}> 
                <Col md={1}></Col><Col md={3} style={{verticalAlign:"sub"}}>Employee Code:</Col><Col md={6}>
                <Form.Control placeholder="Enter Employee Code" type="text" id="employeecode" name="employeecode" value={inputValues[index]?.employeecode || item.emp_code} required onChange={(e) => handleinputchange(e, index)}/>
                </Col>
                </Row>
                <Row align="left" style={{padding:'10px'}}>
                <Col md={1}></Col><Col md={3} style={{verticalAlign:"sub"}}>Employee Name:</Col><Col md={6}>
                <Form.Control placeholder="Enter New Employee Name" type="text" id="employeename" name="employeename" value={inputValues[index]?.employeename || item.emp_name}required onChange={(e) => handleinputchange(e, index)}/>
                </Col>
                </Row>
                <Row align="left" style={{padding:'10px'}}>
                <Col md={1}></Col><Col md={3} style={{verticalAlign:"sub"}}>Employee Location:</Col><Col md={6}> 
                <Form.Select id="employeelocation" name="employeelocation" defaultValue={inputValues[index]?.employeedesignation || item.emp_location} required>
                <option hidden>Select Project</option>
                    {siteOptions.map((option, index) => (
                      <option key={index} value={option.value}>{option.label}</option>
                    ))}
                  </Form.Select>
                </Col>
                </Row>
                <Row align="left" style={{padding:'10px'}}>
                <Col md={1}></Col><Col md={3} style={{verticalAlign:"sub"}}>Designation:</Col><Col md={6}>
                <Form.Control placeholder="Enter Employee Designation" type="text" id="employeedesignation" name="employeedesignation"value={inputValues[index]?.employeedesignation || item.emp_designation} required onChange={(e) => handleinputchange(e, index)}/>
                </Col>
                </Row>
                <Row align="left" style={{padding:'10px'}}>
                <Col md={1}></Col><Col md={3} style={{verticalAlign:"sub"}}>Employee Department:</Col><Col md={6}>
                <Form.Control placeholder="Enter Employee Department" type="text" id="employeedepartment" name="employeedepartment" value={inputValues[index]?.employeedepartment || item.emp_department} required onChange={(e) => handleinputchange(e, index)}/>
                </Col>
                </Row>
                <Row align="left" style={{padding:'10px'}}>
                <Col md={1}></Col><Col md={3} style={{verticalAlign:"sub"}}>Employee DOB:</Col><Col md={6}>
                <Form.Control placeholder="Enter Employee DOB" type="text" id="employeedob" name="employeedob" value={inputValues[index]?.employeedob || item.dob} required onChange={(e) => handleinputchange(e, index)}/></Col>
                </Row>
                <Row align="left" style={{padding:'10px'}}>
                <Col md={1}></Col><Col md={3} style={{verticalAlign:"sub"}}>Employee DOJ:</Col><Col md={6}>
                <Form.Control placeholder="Enter Employee DOJ" type="text" id="employeedoj" name="employeedoj" value={inputValues[index]?.employeedoj || item.doj} required onChange={(e) => handleinputchange(e, index)}/></Col>
                </Row>
                <Row align="left" style={{padding:'10px'}}>
                <Col md={1}></Col><Col md={3} style={{verticalAlign:"sub"}}>Employee Status:</Col><Col md={3}> 
                <Form.Control type="text" value={item.status} readOnly/></Col>
                <Col md={3}> 
                <Form.Select id="employeestatus" name="employeestatus" value={inputValues[index]?.employeestatus || item.status} onChange={(e) => handleinputchange(e, index)} >  
                <option hidden>Select Status</option>
                <option value="probation">Probation</option>
                <option value="working">Working</option>
                <option value="resign">Resign</option>
                <option value="long leave">Long Leave</option>
                <option value="terminated">Terminated</option>
			          </Form.Select> 
                </Col>
                </Row>
                <Row align="left" style={{padding:'10px'}}>
                <Col md={1}></Col><Col md={3} style={{verticalAlign:"sub"}}>Remark:</Col><Col md={6}>
                <Form.Control placeholder="Enter Employee Remark" type="text" id="employeeremark" name="employeeremark" value={inputValues[index]?.employeeremark || item.remark} onChange={(e) => handleinputchange(e, index)} />
                </Col>
                </Row>
                <br/>
               <center> <Button type="submit" variant="primary" style={{backgroundColor:'rgb(0, 189, 252)',width:'50%',height:"50px",borderRadius:"0px 0px 15px 15px",border:'1.5px solid white'}} >Update Employee</Button></center>

               </div></form>
               <div className="modal-footer"> 
               <p>Note:Date Must Be In DD-MM-YYYY Format&#160;&#160;
               <button type="button" className="btn btn-warning" data-bs-dismiss="modal">Close</button></p>
               </div>
                  </div> 
         </div>
         </div> 
                </td>
                <td>{index+1}</td>
                <td>{`${item.emp_code}`}</td>
                <td>{`${item.emp_name}`}</td>
                <td>{`${item.emp_location}`}</td>
                <td>{`${item.emp_designation}`}</td>
                <td>{`${item.emp_department}`}</td>
                <td>{`${item.dob}`}</td>
                <td>{`${item.doj}`}</td>
                <td>{`${item.status}`}</td>
                <td>{`${item.remark}`}</td> 
                </tr></tbody>))}
          </Table>) : (<div> <center><p>No Result</p></center><br/>
             <Table striped bordered hover responsive>
             <thead>
               <tr>
                 <th></th>
                 <th></th>
               <th>Sr No.</th>
                 <th>Emp Code</th>
                 <th>Employee Name</th>
                 <th>Location</th>
                 <th>Designation</th>
                 <th>Department</th>
                 <th>DOB</th> 
                 <th>DOJ</th> 
                 <th>Status</th> 
                 <th>Remarks</th>
               </tr>
             </thead>
             {data.map((item1, index1) =>  ( <tbody><tr key={index1}> 
              <td>
               <form action="https://erp.smpinfra.in/smp/admin/deleteemp.php" method="POST">
               <Form.Control type="text" id="empid" name="empid" value={`${item1.employee_id}`} hidden/> 
             <Button type="submit" variant="primary" style={{backgroundColor:'red',width:'100%',height:"50px",borderRadius:"0px 0px 15px 15px",border:'1.5px solid white'}} >  <FontAwesomeIcon icon={faTrash} /> </Button> 
             </form>
             </td> 
             <td> 
                <Button type="submit" variant="primary" style={{backgroundColor:'rgb(0, 189, 252)',width:'100%',height:"50px",borderRadius:"0px 0px 15px 15px",border:'1.5px solid white'}} data-bs-toggle="modal" data-bs-target={`#editmodal${index1}`}>  <FontAwesomeIcon icon={faEdit} /> </Button> 
                <div className="modal fade" id={`editmodal${index1}`} tabIndex="-1" aria-labelledby={`#editmodalLabel${index1}`} aria-hidden="true">
         <div className="modal-dialog"style={{maxWidth:'78rem'}}>
            <div className="modal-content">
               <div className="modal-header">
               <h5 className="modal-title text-primary" id="exampleModal1Label">Update Employee</h5>
               <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
               </div>
               <div className="modal-body"> 
               <form action="https://erp.smpinfra.in/smp/admin/updateemp.php" method="POST">
               <Row align="left" style={{padding:'10px'}}>
               <Form.Control type="text" id="empid" name="empid" value={`${item1.employee_id}`} hidden/> 
                <Col md={1}></Col><Col md={3} style={{verticalAlign:"sub"}}>Employee Code:</Col><Col md={6}>
                <Form.Control placeholder="Enter Employee Code" type="text" id="employeecode" name="employeecode" defaultValue={item1.emp_code}required />
                </Col>
                </Row>
                <Row align="left" style={{padding:'10px'}}>
                <Col md={1}></Col><Col md={3} style={{verticalAlign:"sub"}}>Employee Name:</Col><Col md={6}>
                <Form.Control placeholder="Enter New Employee Name" type="text" id="employeename" name="employeename" defaultValue={item1.emp_name}required />
                </Col>
                </Row>
                <Row align="left" style={{padding:'10px'}}>
                <Col md={1}></Col><Col md={3} style={{verticalAlign:"sub"}}>Employee Location:</Col><Col md={6}>   
                <Form.Select id="employeelocation" name="employeelocation" defaultValue={item1.emp_location}>
                <option hidden>Select Project</option>
                    {siteOptions.map((option, index) => (
                      <option key={index} value={option.value}>{option.label}</option>
                    ))}
                  </Form.Select>
                </Col>
                </Row>
                <Row align="left" style={{padding:'10px'}}>
                <Col md={1}></Col><Col md={3} style={{verticalAlign:"sub"}}>Designation:</Col><Col md={6}>
                <Form.Control placeholder="Enter Employee Designation" type="text" id="employeedesignation" name="employeedesignation" defaultValue={item1.emp_designation}required/>  
                </Col>
                </Row>
                <Row align="left" style={{padding:'10px'}}>
                <Col md={1}></Col><Col md={3} style={{verticalAlign:"sub"}}>Employee Department:</Col><Col md={6}>
                <Form.Control placeholder="Enter Employee Department" type="text" id="employeedepartment" name="employeedepartment"  defaultValue={item1.emp_department}required/>
                </Col>
                </Row>
                <Row align="left" style={{padding:'10px'}}>
                <Col md={1}></Col><Col md={3} style={{verticalAlign:"sub"}}>Employee DOB:</Col><Col md={6}>
                <Form.Control placeholder="Enter Employee DOB" type="text" id="employeedob" name="employeedob" defaultValue={item1.dob} required/></Col>
                </Row>
                <Row align="left" style={{padding:'10px'}}>
                <Col md={1}></Col><Col md={3} style={{verticalAlign:"sub"}}>Employee DOJ:</Col><Col md={6}>
                <Form.Control placeholder="Enter Employee DOJ" type="text" id="employeedoj" name="employeedoj" defaultValue={item1.doj} required/></Col>
                </Row>
                <Row align="left" style={{padding:'10px'}}>
                <Col md={1}></Col><Col md={3} style={{verticalAlign:"sub"}}>Employee Status:</Col><Col md={3}>
                <Form.Control type="text" value={item1.status} readOnly/>
                </Col><Col md={3}>
                <Form.Select id="employeestatus" name="employeestatus" defaultValue={item1.status} >  
                <option hidden>Select Status</option>
                <option value="probation">Probation</option>
                <option value="working">Working</option>
                <option value="resign">Resign</option>
                <option value="long leave">Long Leave</option>
                <option value="terminated">Terminated</option>
			          </Form.Select> 
                </Col>
                </Row>
                <Row align="left" style={{padding:'10px'}}>
                <Col md={1}></Col><Col md={3} style={{verticalAlign:"sub"}}>Remark:</Col><Col md={6}>
                <Form.Control placeholder="Enter Employee Remark" type="text" id="employeeremark" name="employeeremark" defaultValue={item1.remark}/>
                </Col>
                </Row>
                <br/>
               <center> <Button type="submit" variant="primary" style={{backgroundColor:'rgb(0, 189, 252)',width:'50%',height:"50px",borderRadius:"0px 0px 15px 15px",border:'1.5px solid white'}} >Update Employee</Button></center>
              </form> </div>
               <div className="modal-footer"> 
               <button type="button" className="btn btn-warning" data-bs-dismiss="modal">Close</button>
               </div>
            </div>
         </div>
         </div>
                </td>
             <td>{index1+1}</td>
             <td>{`${item1.emp_code}`}</td>
             <td>{`${item1.emp_name}`}</td>
             <td>{`${item1.emp_location}`}</td>
             <td>{`${item1.emp_designation}`}</td>
             <td>{`${item1.emp_department}`}</td>
             <td>{`${item1.dob}`}</td>
             <td>{`${item1.doj}`}</td>
             <td>{`${item1.status}`}</td>
             <td>{`${item1.remark}`}</td>
             </tr></tbody> ))}
                </Table></div>
               
             )}
        </Container> 
         
      );
}
export default Employee;