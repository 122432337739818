import { BrowserRouter, Routes, Route } from "react-router-dom"; 
import React from 'react'; 
import Sidebar from "./Sidebar"; 
import { Navbar } from "react-bootstrap";
function App() {
  return (
    <div className="App"> 
 <Sidebar/> 
    </div>
  );
}

export default App;
