import React from 'react'; 
import {useState,useEffect} from 'react';
import $ from 'jquery';
const Home1 = () => {
    useEffect(()=>{
		var sid = sessionStorage.getItem("siteid"); 
      var snameses = sessionStorage.getItem("sitename");  
      $("#sname").text(snameses);
  });
    return (<div> 
        <h1 id="sname"></h1></div>);
  };
  
  export default Home1;
