import Table from 'react-bootstrap/Table';
import React from 'react';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Form from 'react-bootstrap/Form';
import Button from 'react-bootstrap/Button';
import {useState,useEffect} from 'react';
import Tab from 'react-bootstrap/Tab';
import Tabs from 'react-bootstrap/Tabs'; 
import $ from 'jquery';
import 'bootstrap/dist/css/bootstrap.min.css';
import swal from 'sweetalert';

function Worker_attendance_sheet() { 
  const [datamain, setDatamain] = useState([]);
  const [datasite, setDatasite] = useState([]);
  const [datadate, setDatadate] = useState([]);
  const [isLoggedIn, setIsLoggedIn] = useState(true); // Assuming the user is initially logged in
  const [logoutTimer, setLogoutTimer] = useState(null); 
  const check = async()=>{
    $.ajax({
      type: "POST",
      url: "https://erp.smpinfra.in/smp/admin/checkdate.php",
      success: function(data) {
        try {
          var Data1 = JSON.parse(data);  
          setDatadate(Data1); 
        } catch(error) {
          console.error("Error parsing JSON:");
        }
      } 
    });
    $.ajax({
      type: "POST",
      url: "https://erp.smpinfra.in/smp/admin/check.php",
      success: function(data) {
        try {
          var Data2 = JSON.parse(data);  
          setDatamain(Data2); 
         // console.log(Data2);
        } catch(error) {
          console.error("Error parsing JSON:");
        }
      } 
    });
  }
  useEffect(()=>{
    check();
		$.ajax({type:"POST",url:"https://erp.smpinfra.in/smp/admin/site_sel.php",success(data){
		  var jsonData = JSON.parse(data);
      setDatasite(jsonData);
		  var table = "<option value=''>Select Site</option><option value='all'>All</option>";
		  for(let i=0;i<jsonData.length;i++){
			  if(jsonData[i].status !== 0){
			  table+="<option value='"+jsonData[i].site_id+"'>"+jsonData[i].site_name+"</option>";
			  }
		  } 
		  document.getElementById("siteid").innerHTML = table; 
		  try{
		  var qstring = window.location.search;
		  var params = new URLSearchParams(qstring);
		  var id = params.get('did');
		  }
		  catch(e){
			  id = '';
		  }
		  $('#siteid').val(id); 
      $('#siteid').change(function () {
        var selectedValue = $(this).val();
        console.log(selectedValue); // You can do whatever you want with the selected value here 
      });
	  }}); 
        
  }, []); 
  const hide = (event) =>{
     document.getElementById('siteid').style.display="";
     document.getElementById('datecreated1').style.display="";
     var report1 = $('#report').val(); 
     
     if(report1=="contractorattendance"){ 
      document.getElementById('siteid').style.display="";
      document.getElementById('datecreated').style.display="";
      document.getElementById('datecreated1').style.display="";
      document.getElementById('mainbutton').style.display="";
      document.getElementById('newbutton').style.display="none";
      document.getElementById('mon').style.display="none"; 
     }
     else if(report1=="staffattendance"){ 
      document.getElementById('siteid').style.display="";
      document.getElementById('datecreated1').style.display="";
      document.getElementById('datecreated').style.display="";
      document.getElementById('mainbutton').style.display="";
      document.getElementById('newbutton').style.display="none";
      document.getElementById('mon').style.display="none"; 
     }
     else if(report1=="manpowerreport"){ 
      document.getElementById('siteid').style.display="";
      document.getElementById('datecreated').style.display="";
      document.getElementById('datecreated1').style.display="";
      document.getElementById('mainbutton').style.display="";
      document.getElementById('newbutton').style.display="none";
      document.getElementById('mon').style.display="none"; 
      var site1 = $('#siteid').val();
      if(site1=="all"){
        document.getElementById('datecreated1').style.display="none";
      }
     }
     else if(report1=="manpowersummary"){ 
      document.getElementById('siteid').style.display="none";
      document.getElementById('datecreated').style.display="";
      document.getElementById('datecreated1').style.display="none";
      document.getElementById('mainbutton').style.display="none";
      document.getElementById('newbutton').style.display="";
      document.getElementById('mon').style.display="none";  
     }
     else if(report1=="allempatd"){ 
      document.getElementById('siteid').style.display="none";
      document.getElementById('datecreated').style.display="none";
      document.getElementById('datecreated1').style.display="none";
      document.getElementById('mainbutton').style.display="";
      document.getElementById('newbutton').style.display="none";
      document.getElementById('mon').style.display=''; 
     }
     else{}
  }
   
  const redirect = (event) =>{
    var report = $('#report').val();
    var siteid = $('#siteid').val();
    var datecreated = $('#datecreated').val();
    var datecreated1 = $('#datecreated1').val(); 
    var monthyear = $('#monthyear').val(); 
    if(report!=="allempatd"){
 if(datecreated==null || datecreated==""){
    swal({title:"Error!",text:"Date Can Not Be Blank!",icon:"error",buttons:false,timer:2000}).then(()=>{
      $('#datecreated').focus();
    });
  }else{
    if(report=="contractorattendance"){ 
      if(siteid==null || siteid==""){ 
        swal({title:"Error!",text:"Project Can Not Be Blank!",icon:"error",buttons:false,timer:2000}).then(()=>{
          $('#siteid').focus();
        });
      }
      else if(siteid=="all"){
       window.location.href="https://erp.smpinfra.in/smp/admin/siteall_con_attendance.php?datecreated="+datecreated+"&datecreated1="+datecreated1;
        setTimeout(() => {
          window.location.reload();
        }, 300000);
      }
      else{
        window.location.href="https://erp.smpinfra.in/smp/admin/worker_attendance.php?siteid="+siteid+"&datecreated="+datecreated+"&datecreated1="+datecreated1;
        setTimeout(() => {
          window.location.reload();
        }, 300000); 
        //emp_attendance
      }}
     else if(report=="staffattendance"){ 
      if(siteid==null || siteid==""){ 
        swal({title:"Error!",text:"Project Can Not Be Blank!",icon:"error",buttons:false,timer:2000}).then(()=>{
          $('#siteid').focus();
        });
      }
      else if(siteid=="all"){
        window.location.href="https://erp.smpinfra.in/smp/admin/empall_attendance.php?datecreated="+datecreated+"&datecreated1="+datecreated1;
         setTimeout(() => {
           window.location.reload();
         }, 300000);
       }
      else{
        window.location.href="https://erp.smpinfra.in/smp/admin/emp_attendance.php?siteid="+siteid+"&datecreated="+datecreated+"&datecreated1="+datecreated1;
        setTimeout(() => {
          window.location.reload();
        }, 300000); 
        //emp_attendance
      }
      }
      else if(report=="manpowerreport"){ 
        if(siteid==null || siteid==""){ 
          swal({title:"Error!",text:"Project Can Not Be Blank!",icon:"error",buttons:false,timer:2000}).then(()=>{
            $('#siteid').focus();
          });
        }
        else if(siteid=="all"){
          window.location.href="https://erp.smpinfra.in/smp/admin/allsitemanpowersummary.php?datecreated="+datecreated;
           setTimeout(() => {
             window.location.reload();
           }, 300000);
         }
       else{
        window.location.href="https://erp.smpinfra.in/smp/admin/manpowerreport.php?siteid="+siteid+"&datecreated="+datecreated+"&datecreated1="+datecreated1; 
          setTimeout(() => {
            window.location.reload();
          }, 300000);
        //emp_attendance 
       }
      }
      else{
        alert('select document');
      }
    }
  }else{
    if(report="allempatd"){
      if(monthyear==null || monthyear==""){
        swal({title:"Error!",text:"Date Can Not Be Blank!",icon:"error",buttons:false,timer:2000}).then(()=>{
          $('#monthyear').focus();
        });}
        else{
          window.location.href="https://erp.smpinfra.in/smp/admin/allempatd.php?mon="+monthyear; 
          setTimeout(() => {
            window.location.reload();
          },10000);
        }
    }
  }
  } 
  const redirect_one = (event) =>{
    var report = $('#report').val();
    var siteid = $('#siteid').val();
    var datecreated = $('#datecreated').val(); 
    
 if(datecreated==null || datecreated==""){
    swal({title:"Error!",text:"Date Can Not Be Blank!",icon:"error",buttons:false,timer:2000}).then(()=>{
      $('#datecreated').focus();
    });
  }else{
      if(report=="manpowersummary"){ 
        window.location.href="https://erp.smpinfra.in/smp/admin/summary.php?datecreated="+datecreated; 
          setTimeout(() => {
            window.location.reload();
          },3000); 
        //emp_attendance 
      }
      else{
        alert('select document');
      }
    }
  } 
  const redirect_two = (event) =>{
    var report = $('#report').val();
    var siteid = $('#siteid').val();
    var datecreated = $('#datecreated').val(); 
    
 if(datecreated==null || datecreated==""){
    swal({title:"Error!",text:"Date Can Not Be Blank!",icon:"error",buttons:false,timer:2000}).then(()=>{
      $('#datecreated').focus();
    });
  }else{
      if(report=="manpowersummary"){ 
        window.location.href="https://erp.smpinfra.in/smp/admin/allsitemanpowersummary.php?datecreated="+datecreated;
        setTimeout(() => {
          window.location.reload();
        },280000);
        //emp_attendance 
      }
      else{
        alert('select document');
      }
    }
  } 
  const [startDate, setStartDate] = useState('');
  const [endDate, setEndDate] = useState(''); 
  const handleStartDateChange = (e) => {
    const selectedDate = new Date(e.target.value);setStartDate(selectedDate.toISOString().slice(0, 10)); 
    const endDate = new Date(selectedDate);
    endDate.setMonth(endDate.getMonth() + 1);
    endDate.setDate(0); 
    setEndDate(endDate.toISOString().slice(0, 10));
  }
  const logout=()=>{
    setIsLoggedIn(false);
    sessionStorage.removeItem("logstatus");
    window.location.reload();
  }
  const handleActivity = () => {
    // Reset the logout timer on user activity
    if (logoutTimer) {
      clearTimeout(logoutTimer);
    }
    const timer = setTimeout(logout, 15 * 60 * 1000); // Reset timer to 3 minutes
    setLogoutTimer(timer);
  } 
  let date = [];
  const grid = (event) =>{
    document.getElementById("grid").style.display='';
  }
  return ( <div>
     <Container style={{color: "#565656",padding:'0',backgroundColor:'white',maxWidth:'80rem',borderRadius:'15px',boxShadow: '0 1px 6px rgba(0, 0, 0, 0.12), 0 1px 4px rgba(0, 0, 0, 0.24)',display:''}} fluid onMouseMove={handleActivity} onClick={handleActivity} onKeyDown={handleActivity} id="dis"> <br/>
     <div style={{ overflowX: 'auto', maxHeight: '250px'}}>  
     <Table striped bordered hover responsive>
                <tbody>  <tr><td>Project</td>
                {datadate.map((item2, index2) => { 
                  date.push(item2); 
                  return (
                  <td key={index2}>{item2}</td>)
                 })}</tr>
      {datasite.map((item3, index3) => (
    <tr key={index3}>
        <td>{item3.site_code}</td>
        {date.map((dateItem1, index4) => {
            const parts = dateItem1.split('-');
            const formattedDate = `${parts[2]}-${parts[1]}-${parts[0]}`;
            console.log('Checking date:', formattedDate);
            const isDateMatch = datamain.some(data => data.date_reported === formattedDate);
            const isSiteMatch = datamain.some(data => data.sitename === item3.site_code);
            console.log('isDateMatch:', isDateMatch, 'isSiteMatch:', isSiteMatch);
            return (
                <td key={index4}>
                    {isDateMatch && isSiteMatch ? '✔' : '✘'}
                </td>
            );
        })}
    </tr>
))}
                  </tbody></Table></div>
    
     </Container><br/>
  <Container style={{color: "#565656",padding:'0',backgroundColor:'white',maxWidth:'80rem',borderRadius:'15px',boxShadow: '0 1px 6px rgba(0, 0, 0, 0.12), 0 1px 4px rgba(0, 0, 0, 0.24)'}} fluid onMouseMove={handleActivity} onClick={handleActivity} onKeyDown={handleActivity}>
  <br/>
  <h2 align="center">Worker Attendance</h2>
 <hr/> 
 <Row align="left" style={{padding:'10px'}}>
  <Col md={1}>Select Document</Col>
                <Col md={10}>
                <Form.Select id="report" name="report" onChange={hide}> 
                <option hidden>Select Document</option>
                <option value="contractorattendance">Contractor Attendance</option>
                <option value="staffattendance">Employee Attendance</option>
                <option value="manpowerreport">Man Power Report</option>
                <option value="manpowersummary">Man Power Summary</option>
                <option value="allempatd">All Employee Attendance</option>
			</Form.Select>
                </Col></Row>
  <Row align="left" style={{padding:'10px'}}>
  <Col md={1}>Select Project</Col>
                <Col md={10}>
                <Form.Select id="siteid" name="siteid"> 
			</Form.Select>
                </Col></Row> <Row align="left" style={{padding:'10px'}}>
                <Col md={1}>From Date</Col>
                <Col md={5}><Form.Control type="date" id="datecreated" name="datecreated" value={startDate} onChange={handleStartDateChange}/>
                </Col> 
                <Col md={1}>To Date</Col>
<Col md={5}><Form.Control type="date" id="datecreated1" name="datecreated1" min={startDate} max={endDate} />
                </Col>
                </Row> 
                <Row align="left" style={{padding:'10px',display:'none'}} id="mon">
                <Col md={2}>Select Month-Year</Col>
                <Col md={9}><Form.Control type="month" id="monthyear" name="monthyear"/>
                </Col>  
                </Row> 
                <Button type="submit" variant="primary" style={{backgroundColor:'rgb(0, 189, 252)',width:'100%',height:"50px",borderRadius:"0px 0px 15px 15px",border:'1.5px solid white'}} id="mainbutton" onClick={redirect}>Print Report</Button>
                <br/>
                <div style={{display:'none'}} id="newbutton">
                <Button type="submit" variant="primary" style={{backgroundColor:'rgb(0, 189, 252)',width:'50%',height:"50px",borderRadius:"0px 0px 15px 15px",border:'1.5px solid white'}} onClick={redirect_one}>Print Summary</Button>
                <Button type="submit" variant="primary" style={{backgroundColor:'rgb(0, 189, 252)',width:'50%',height:"50px",borderRadius:"0px 0px 15px 15px",border:'1.5px solid white'}} onClick={redirect_two}>Print Man Power Report</Button> 
                </div>
    </Container></div>
  );
}

export default Worker_attendance_sheet;