import React from 'react';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Form from 'react-bootstrap/Form';
import Button from 'react-bootstrap/Button';
import {useState,useEffect} from 'react';
import Tab from 'react-bootstrap/Tab';
import Tabs from 'react-bootstrap/Tabs';
import Table from 'react-bootstrap/Table';
import $ from 'jquery';
import 'bootstrap/dist/css/bootstrap.min.css';
import swal from 'sweetalert';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTrash,faEdit, faTrashRestoreAlt } from '@fortawesome/free-solid-svg-icons';


const User = () => { 
  const [data, setData] = useState([]); 
  const [isLoggedIn, setIsLoggedIn] = useState(true); // Assuming the user is initially logged in
  const [logoutTimer, setLogoutTimer] = useState(null);
  useEffect(()=>{ 
		$.ajax({type:"POST",url:"https://erp.smpinfra.in/smp/admin/site_sel.php",success(data){
		  var jsonData = JSON.parse(data);
		  var table = "<option value=''>Select Site</option>";
		  for(let i=0;i<jsonData.length;i++){
			  if(jsonData[i].status !== 0){
			  table+="<option value='"+jsonData[i].site_code+"'>"+jsonData[i].site_name+"</option>";
			  }
		  } 
		  document.getElementById("siteid").innerHTML = table;   
		  try{
		  var qstring = window.location.search;
		  var params = new URLSearchParams(qstring);
		  var id = params.get('did');
		  }
		  catch(e){
			  id = '';
		  }
		  $('#siteid').val(id); 
      $('#siteid').change(function () {
        var selectedValue = $(this).val();
        console.log(selectedValue); // You can do whatever you want with the selected value here siteidsearch
      }); 
	  }
  });
    try {
      $.ajax({type:"POST",url:"https://erp.smpinfra.in/smp/admin/select_user_all.php" ,success(data){
        var jsonData = JSON.parse(data); 
        document.cookie = jsonData.length; 
        setData(jsonData);
      }});
    }
    catch (error) {
      console.error('Error fetching data:', error);
    }  
  }, []);  
  
  const [query, setQuery] = useState('');
  const [results, setResults] = useState([]);

  const handleInputChange = (event) => {
      setQuery(event.target.value);
      fetchSearchResults(event.target.value);
  };
  const fetchSearchResults = (searchQuery) => {
    fetch(`https://erp.smpinfra.in/smp/admin/filter_user.php?query=${searchQuery}`)
        .then(response => response.json())
        .then(data => {
            setResults(data);
        })
        .catch(error => {
            console.error('Error fetching search results:', error);
        });
};
    const user_add=(event)=>{ 
      var username = $('#username').val();
      var pass = $('#pass').val();  
      var siteid = $('#siteid').val();
      var email = $('#email').val();
      var role = $('#role').val();
     try{
      if(username==null || username==""){
        swal({title:"Error!",text:"User Name Can Not Be Blank!",icon:"error",buttons:false,timer:2000}).then(()=>{
          $('#username').focus();
        });
      }else if(pass==null || pass==""){
        swal({title:"Error!",text:"Password Can Not Be Blank!",icon:"error",buttons:false,timer:2000}).then(()=>{
            $('#pass').focus();
          });
      }
      else if(role==null || role==""){
        swal({title:"Error!",text:"Role Can Not Be Blank!",icon:"error",buttons:false,timer:2000}).then(()=>{
            $('#role').focus();
          });
      }
       else{ $.ajax({type:"POST",url:"https://erp.smpinfra.in/smp/admin/user_add.php",data:{username:username,pass:pass,siteid:siteid,email:email,role:role},success(data){
          if(data == 1){
            swal({title:"Successful!",text:"New User Added Successfully",icon:"success",buttons:false,timer:2000}).then(()=>{
              window.location.reload();
            });
            }
            else{
              swal({title:"Error!",text:"User Already Exists",icon:"error",buttons:false,timer:2000}).then(()=>{
                window.location.reload();
              });
            }
        }});}}
        catch(e){
          alert(e);
        }
    } 
    const exportmaster = (event) =>{
      window.location.href = "https://erp.smpinfra.in/smp/admin/export_user_master.php";
     } 
     const logout=()=>{
      setIsLoggedIn(false);
      sessionStorage.removeItem("logstatus");
      window.location.reload();
    }
     const handleActivity = () => {
      // Reset the logout timer on user activity
      if (logoutTimer) {
        clearTimeout(logoutTimer);
      }
      const timer = setTimeout(logout, 15 * 60 * 1000); // Reset timer to 3 minutes
      setLogoutTimer(timer);
    };
    return (
        <Container style={{color: "#565656",padding:'0',backgroundColor:'white',maxWidth:'78rem',borderRadius:'15px',boxShadow: '0 1px 6px rgba(0, 0, 0, 0.12), 0 1px 4px rgba(0, 0, 0, 0.24)'}} fluid onMouseMove={handleActivity} onClick={handleActivity} onKeyDown={handleActivity}>
                <br/>
                <h2 align="center">Add New User</h2>
                <hr/>
                
                <Row align="left" style={{padding:'10px'}}>
                <Col md={1}></Col><Col md={1} style={{verticalAlign:"sub"}}>User Name:</Col><Col md={4}>
                <Form.Control placeholder="Enter User Name" type="text" id="username" name="username"/> </Col>
                <Col md={1} style={{verticalAlign:"sub"}}> Password:</Col><Col md={4}>
                <Form.Control placeholder="Enter Password" type="text" id="pass" name="pass"/></Col>
                </Row>
                <Row align="left" style={{ padding: '10px' }}><Col md={1}></Col><Col md={1} style={{verticalAlign:"sub"}}> Select Project:</Col>
                <Col md={4}>
              <Form.Select id="siteid">
              </Form.Select>
              </Col> 
                 <Col md={1} style={{verticalAlign:"sub"}}> EmailID:</Col>
                 <Col md={4}>
                <Form.Control placeholder="Enter Email" type="email" id="email" name="email"/></Col>
                </Row> 
                <Row align="left" style={{ padding: '10px' }}><Col md={1}></Col><Col md={1} style={{verticalAlign:"sub"}}> Select Role:</Col>
                <Col md={9}>
              <Form.Select id="role">
                <option hidden>Select Role</option>
                <option value="project">Project</option>
                <option value="all project">All Project</option>
                <option value="all project-master">All Project + Master</option>
                <option value="all project-master-user">All Project + Master + User</option>
              </Form.Select>
              </Col>
              </Row> 
                <br/>
                <Button type="submit" variant="primary" style={{backgroundColor:'rgb(0, 189, 252)',width:'50%',height:"50px",borderRadius:"0px 0px 15px 15px",border:'1.5px solid white'}} onClick={user_add}>Add User</Button> 
                <Button type="button" variant="primary" style={{backgroundColor:'rgb(0, 189, 252)',width:'50%',height:"50px",borderRadius:"0px 0px 15px 15px",border:'1.5px solid white'}} onClick={exportmaster}>Export User Master</Button>
                <br/><br/>

                <Row align="left" style={{padding:'10px'}}> <center>
                <Col md={11}>
                <Form.Control type="text"value={query}
                onChange={handleInputChange}
                placeholder="Search by username,password,email,project name or role" /></Col></center></Row><br/>

                {results.length > 0 ? (
                    <Table striped bordered hover responsive>
                    <thead>
                      <tr>
                        <th></th>
                        <th></th>
                      <th>Sr No.</th>
                        <th>User Name</th>
                        <th>Password</th>
                        <th>Project</th>
                        <th>Email</th> 
                        <th>Role</th>
                      </tr>
                    </thead>
                    {results.map((item1, index1) =>  ( 
              <tbody><tr key={index1}> 
                <td>
                <form action="https://erp.smpinfra.in/smp/admin/deleteuser.php" method="POST">
                <Form.Control type="text" id="userid" name="userid" value={`${item1.userid}`} hidden/> 
              <Button type="submit" variant="primary" style={{backgroundColor:'red',width:'100%',height:"50px",borderRadius:"0px 0px 15px 15px",border:'1.5px solid white'}} >  <FontAwesomeIcon icon={faTrash} /> </Button> 
              </form>
              </td> 
              <td>
              <Button type="submit" variant="primary" style={{backgroundColor:'rgb(0, 189, 252)',width:'100%',height:"50px",borderRadius:"0px 0px 15px 15px",border:'1.5px solid white'}} data-bs-toggle="modal" data-bs-target={`#search${item1.uname}`}>  <FontAwesomeIcon icon={faEdit} /> </Button> 
                <div className="modal fade" id={`search${item1.uname}`} tabIndex="-1" aria-labelledby={`searchLabel${item1.uname}`} aria-hidden="true">
         <div className="modal-dialog"style={{maxWidth:'78rem'}}>
            <div className="modal-content">
               <div className="modal-header">
               <h5 className="modal-title text-primary" id="searchLabel">Update User</h5>
               <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
               </div><form action="https://erp.smpinfra.in/smp/admin/updateuser.php" method="POST">
               <div className="modal-body"> 
               <Form.Control type="text" id="userid" name="userid" value={`${item1.userid}`} hidden/>
               <Row align="left" style={{padding:'10px'}}>
                <Col md={1}></Col><Col md={1} style={{verticalAlign:"sub"}}>User Name:</Col><Col md={4}> 
                <Form.Control placeholder="Enter User Name" type="text" id="username" name="username" defaultValue={item1.uname}/> </Col>
                <Col md={1} style={{verticalAlign:"sub"}}> Password:</Col><Col md={4}>
                <Form.Control placeholder="Enter Password" type="text" id="pass" name="pass" defaultValue={item1.upassword}/></Col>
                </Row>
                <Row align="left" style={{ padding: '10px' }}><Col md={1}></Col><Col md={1} style={{verticalAlign:"sub"}}> Select Project:</Col>
                <Col md={4} id="sitetext" style={{display:''}}>
                <Form.Control type="text" id="siteidsearchtext" name="siteidsearch" defaultValue={item1.usite}/>  
              </Col>  
                 <Col md={1} style={{verticalAlign:"sub"}}> EmailID:</Col>
                 <Col md={4}>
                <Form.Control placeholder="Enter Email" type="email" id="email" name="email" defaultValue={item1.uemail}/></Col>
                </Row>  
              <Row align="left" style={{ padding: '10px' ,display:''}} id="role1"><Col md={1}></Col><Col md={1} style={{verticalAlign:"sub"}}> Select Role:</Col>
                <Col md={9}>
              <Form.Control type="text" defaultValue={item1.role} name="rolemain"   id="rolemain"></Form.Control>
              </Col>
              </Row>
                    <center> <Button type="submit" variant="primary" style={{backgroundColor:'rgb(0, 189, 252)',width:'50%',height:"50px",borderRadius:"0px 0px 15px 15px",border:'1.5px solid white'}} >Update User</Button></center> 
               </div></form>
               <div className="modal-footer"> 
               <p>Note:Date Must Be In DD-MM-YYYY Format&#160;&#160;
               <button type="button" className="btn btn-warning" data-bs-dismiss="modal">Close</button></p>
               </div>
                  </div> 
         </div>
         </div>  
                 </td>
              <td>{index1+1}</td>
              <td>{`${item1.uname}`}</td>
              <td>{`${item1.upassword}`}</td>
              <td>{`${item1.usite}`}</td>
              <td>{`${item1.uemail}`}</td> 
              <td>{`${item1.role}`}</td> 
              </tr></tbody>
                ))}
          </Table> ) : (
            <div> <center><p>No Result</p></center><br/>
              <Table striped bordered hover responsive>
              <thead>
                <tr>
                  <th></th>
                  <th></th>
                <th>Sr No.</th>
                  <th>User Name</th>
                  <th>Password</th>
                  <th>Project</th>
                  <th>Email</th> 
                  <th>Role</th>
                </tr>
              </thead>
                {data.map((item, index) =>  ( <tbody><tr key={index}> 
                <td>
                  <form action="https://erp.smpinfra.in/smp/admin/deleteuser.php" method="POST">
                  <Form.Control type="text" id="userid" name="userid" value={`${item.userid}`} hidden/> 
                <Button type="submit" variant="primary" style={{backgroundColor:'red',width:'100%',height:"50px",borderRadius:"0px 0px 15px 15px",border:'1.5px solid white'}} >  <FontAwesomeIcon icon={faTrash} /> </Button> 
                </form>
                </td> 
                <td> 
                <Button type="submit" variant="primary" style={{backgroundColor:'rgb(0, 189, 252)',width:'100%',height:"50px",borderRadius:"0px 0px 15px 15px",border:'1.5px solid white'}} data-bs-toggle="modal" data-bs-target={`#editmodal${index}`}>  <FontAwesomeIcon icon={faEdit} /> </Button> 
                <div className="modal fade" id={`editmodal${index}`} tabIndex="-1" aria-labelledby={`editmodalLabel${index}`} aria-hidden="true">
         <div className="modal-dialog"style={{maxWidth:'78rem'}}>
            <div className="modal-content">
               <div className="modal-header">
               <h5 className="modal-title text-primary" id="editModal1Label">Update User</h5>
               <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
               </div>
               <div className="modal-body"> 
               <form action="https://erp.smpinfra.in/smp/admin/updateuser.php" method="POST">  
               <Row align="left" style={{padding:'10px'}}>
                <Col md={1}></Col><Col md={1} style={{verticalAlign:"sub"}}>User Name:</Col><Col md={4}>
                <Form.Control type="text" id="userid" name="userid" value={`${item.userid}`} hidden/>
                <Form.Control placeholder="Enter User Name" type="text" id="username" name="username" defaultValue={item.uname}/> </Col>
                <Col md={1} style={{verticalAlign:"sub"}}> Password:</Col><Col md={4}>
                <Form.Control placeholder="Enter Password" type="text" id="pass" name="pass" defaultValue={item.upassword}/></Col>
                </Row>
                <Row align="left" style={{ padding: '10px' }}><Col md={1}></Col><Col md={1} style={{verticalAlign:"sub"}}> Select Project:</Col>
                <Col md={4} id="sitetext" style={{display:''}}>
                <Form.Control type="text" id="siteidsearchtext" name="siteidsearch" defaultValue={item.usite}/>  
              </Col>  
                 <Col md={1} style={{verticalAlign:"sub"}}> EmailID:</Col>
                 <Col md={4}>
                <Form.Control placeholder="Enter Email" type="email" id="email" name="email" defaultValue={item.uemail}/></Col>
                </Row>  
              <Row align="left" style={{ padding: '10px' ,display:''}} id="role1"><Col md={1}></Col><Col md={1} style={{verticalAlign:"sub"}}> Select Role:</Col>
                <Col md={9}>
              <Form.Control type="text" defaultValue={item.role} name="rolemain"   id="rolemain"></Form.Control>
              </Col>
              </Row>
                <center> <Button type="submit" variant="primary" style={{backgroundColor:'rgb(0, 189, 252)',width:'50%',height:"50px",borderRadius:"0px 0px 15px 15px",border:'1.5px solid white'}} >Update User</Button></center>
                </form> </div>
               <div className="modal-footer">  
               <button type="button" className="btn btn-warning" data-bs-dismiss="modal">Close</button> 
               </div>
                  </div> 
         </div>
         </div> 
                </td>
                <td>{index+1}</td>
                <td>{`${item.uname}`}</td>
                <td>{`${item.upassword}`}</td>
                <td>{`${item.usite}`}</td>
                <td>{`${item.uemail}`}</td> 
                <td>{`${item.role}`}</td>
                </tr></tbody>))}
          </Table></div>)}
        </Container>
      );
}
export default User;