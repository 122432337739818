import React from 'react';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Form from 'react-bootstrap/Form';
import Button from 'react-bootstrap/Button';
import {useState,useEffect} from 'react';
import Tab from 'react-bootstrap/Tab';
import Tabs from 'react-bootstrap/Tabs';
import Table from 'react-bootstrap/Table';
import $ from 'jquery';
import 'bootstrap/dist/css/bootstrap.min.css';
import swal from 'sweetalert';  
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTrash,faEdit, faTrashRestoreAlt } from '@fortawesome/free-solid-svg-icons';
 
function Agencysel() { 
  const [data, setData] = useState([]); 
  const [sel, setSel] = useState([]); 
  const [code, setCode] = useState([]); 
  const [defaultOption, setDefaultOption] = useState(''); 
  const [isLoggedIn, setIsLoggedIn] = useState(true); // Assuming the user is initially logged in
  const [logoutTimer, setLogoutTimer] = useState(null);

  const [query, setQuery] = useState('');
  const [results, setResults] = useState([]);
  const handleInputChange = (event) => {
      setQuery(event.target.value);
      fetchSearchResults(event.target.value);
  };
  const [inputValues, setInputValues] = useState({}); 
  const handleinputchange1 = (e, index) => {
    const { name, value } = e.target;
    setInputValues(prevState => ({
      ...prevState,
      [index]: {
        ...prevState[index],
        [name]: value
      }
    }));
  }; 
  const fetchSearchResults = (searchQuery) => {
      fetch(`https://erp.smpinfra.in/smp/admin/filter_ageman.php?query=${searchQuery}`)
          .then(response => response.json())
          .then(data => {
              setResults(data);
          })
          .catch(error => {
              console.error('Error fetching search results:', error);
          });
  };

  useEffect(()=>{
   
		$.ajax({type:"POST",url:"https://erp.smpinfra.in/smp/admin/site_sel.php",success(data){
		  var jsonData = JSON.parse(data);
		  var table = "<option value=''>Select Site</option>";
		  for(let i=0;i<jsonData.length;i++){
			  if(jsonData[i].status !== 0){
			  table+="<option value='"+jsonData[i].site_code+"'>"+jsonData[i].site_code+"</option>";
			  }
		  } 
		  document.getElementById("siteid").innerHTML = table; 
		  try{
		  var qstring = window.location.search;
		  var params = new URLSearchParams(qstring);
		  var id = params.get('did');
		  }
		  catch(e){
			  id = '';
		  }
		  $('#siteid').val(id);    
      $('#siteid').change(function () {
        var selectedValue = $(this).val();
        console.log(selectedValue); // You can do whatever you want with the selected value here
      }); 
	  }});
  },[]);  
  const fetchagency = async () => {
    const siteid = $('#siteid').val();  
    $.ajax({type:"POST",url:"https://erp.smpinfra.in/smp/admin/selmandayagency.php",data:{siteid:siteid},success(data){
      var jsonDatamain = JSON.parse(data);  
      setSel(jsonDatamain);
    }}); 
    try {
        $.ajax({
            type: "POST",
            url: "https://erp.smpinfra.in/smp/admin/age_sel_3.php",
            data: { siteid: siteid },
            success: (data) => { 
                try {
                    const jsonData1 = JSON.parse(data);   
                    setData(jsonData1);
                } catch (e) {
                    console.error('Error parsing JSON:', e, 'Response data:', data);
                }
            },
            error: (jqXHR, textStatus, errorThrown) => {
                console.error('AJAX error:', textStatus, 'Detail:', errorThrown);
            }
        });
    } catch (error) {
        console.error('Error fetching data:', error);
    }
};
  
const logout=()=>{
    setIsLoggedIn(false);
		sessionStorage.removeItem("logstatus");
		window.location.reload();
	}
   const handleActivity = () => {
    // Reset the logout timer on user activity
    if (logoutTimer) {
      clearTimeout(logoutTimer);
    }
    const timer = setTimeout(logout, 15 * 60 * 1000); // Reset timer to 3 minutes
    setLogoutTimer(timer);
  };
  const numberOfRows = 5;
  const fetchagecode = async (event, index) => {
    const agename = event.target.value; 
    if (agename !== '' && agename !== 'Select Agency') { 
      try {
        $.ajax({
          type: "POST",
          url: "https://erp.smpinfra.in/smp/admin/agency_code.php", 
          data: {agename:agename},
          success: (data) => { 
            const jsonData2 = JSON.parse(data);   
            document.getElementById(`agencycode${index}`).value = jsonData2[0].agency_code;
          },
          error: (error) => {
            console.error('Error fetching agency code:', error);
          }
        });
      } catch (error) {
        console.error('Error in AJAX call:', error);
      }
    }
  };
  const submitdata = (event) =>{
    for (let i = 0; i < 5; i++) { 
      var sitename = $('#siteid').val(); 
      var agename = ($('#ageid'+[i]).val());
      var agencycode = ($('#agencycode'+[i]).val());
      var type1 = ($('#type1'+[i]).val()); 
      var type2 = ($('#type2'+[i]).val()); 
      var type3 = ($('#type3'+[i]).val());
      var type4 = ($('#type4'+[i]).val());
      var type5 = ($('#type5'+[i]).val());
      var type6 = ($('#type6'+[i]).val());
      //alert(agename);
      $.ajax({type:"POST",url:"https://erp.smpinfra.in/smp/admin/addselagency.php",data:{sitename:sitename,agename:agename,agencycode:agencycode,type1:type1,type2:type2,type3:type3,type4:type4,type5:type5,type6:type6},success(datanew){
        if(datanew){
          swal({title:"Successful!",text:"Added Successfully",icon:"success",buttons:false,timer:2000}).then(()=>{
            window.location.reload();
          });
          }  
        }}); 
    }
  }
return ( 
<div><Container style={{ color: "#565656", padding: '0', backgroundColor: 'white', maxWidth: '80rem', borderRadius: '15px', boxShadow: '0 1px 6px rgba(0, 0, 0, 0.12), 0 1px 4px rgba(0, 0, 0, 0.24)' }} fluid onMouseMove={handleActivity} onClick={handleActivity} onKeyDown={handleActivity}>
   <br />
   <h2 align="center">Select Contractor</h2>
   <hr />
   <Row align="left" style={{ padding: '10px' }}>
   <Col md={12}>
       <Form.Select id="siteid" onChange={fetchagency}>
       </Form.Select> </Col>  
   </Row> 
  
   <Table striped bordered hover responsive> 
     <thead style={{ position:'sticky', zIndex: '1' }}> 
     <tr> 
      <th style={{width:'220px'}}>Contractor Name</th>
       <th>Contractor Code</th>
       <th>Type 1</th>
       <th>Type 2</th>
       <th>Type 3</th>
       <th>Type 4</th>
       <th>Type 5</th> 
       <th>Type 6</th> 
     </tr>
      
     </thead>
     <tbody>
     {[...Array(numberOfRows)].map((_, index) => (
          <tr key={index}>
            <td>
              <Form.Select id={`ageid${index}`} onChange={(e) =>{ fetchagecode(e, index)}}required> 
              <option hidden>Select Agency</option>
              {data.map((agency, idx) => (
                 <option key={idx} value={agency.contractor_name}>
                    {agency.contractor_name}
                 </option>
                ))}
              </Form.Select>
            </td>
            <td><Form.Control type="text" id={`agencycode${index}`} name={`agencycode`} readOnly/></td>
            <td><Form.Control type="text" id={`type1${index}`} name={`type1`} placeholder='type1'/></td>
            <td><Form.Control type="text" id={`type2${index}`} name={`type2`} placeholder='type2'/></td>
            <td><Form.Control type="text" id={`type3${index}`} name={`type3`} placeholder='type3'/></td>
            <td><Form.Control type="text" id={`type4${index}`} name={`type4`} placeholder='type4'/></td>
            <td><Form.Control type="text" id={`type5${index}`} name={`type5`} placeholder='type5'/></td>
            <td><Form.Control type="text" id={`type6${index}`} name={`type6`} placeholder='type6'/></td>
          </tr>
        ))} 
     </tbody>
       </Table> 
       <Button type="submit" variant="primary" style={{ backgroundColor: 'rgb(0, 189, 252)', width: '100%', height: "50px", borderRadius: "0px 0px 15px 15px", border: '1.5px solid white' }} onClick={submitdata}>Save</Button> 
 <br/><br/>
                <Row align="left" style={{padding:'10px'}}> <center>
                <Col md={11}>
                <Form.Control type="text" value={query}
                onChange={handleInputChange}
                placeholder="Search by name,code,parts,location,start-date or total cost" /></Col></center></Row><br/>
                 {results.length > 0 ? (
                  <Table striped bordered hover responsive>
                  <thead>
                    <tr>
                    <th></th> 
                    <th></th> 
                      <th>Sr No.</th>
                      <th>Project Name</th>
                      <th>Agency Code</th>
                      <th>Agency Name</th>
                      <th>Type1</th> 
                      <th>Type2</th> 
                      <th>Type3</th> 
                      <th>Type4</th> 
                      <th>Type5</th> 
                      <th>Type6</th>  
                    </tr>
                  </thead>  
                  {results.map((item, index) =>  ( <tbody><tr key={index}>
                      <td> <form action="https://erp.smpinfra.in/smp/admin/deleteagemanday.php" method="POST">
                    <Form.Control type="text" id="ageid" name="ageid" value={`${item.agencycode}`} hidden/> 
                  <Button type="submit" variant="primary" style={{backgroundColor:'red',width:'100%',height:"50px",borderRadius:"0px 0px 15px 15px",border:'1.5px solid white'}} >  <FontAwesomeIcon icon={faTrash} /> </Button> 
                  </form></td>
                  <td> 
                <Button type="submit" variant="primary" style={{backgroundColor:'rgb(0, 189, 252)',width:'100%',height:"50px",borderRadius:"0px 0px 15px 15px",border:'1.5px solid white'}} data-bs-toggle="modal" data-bs-target={`#${item.agencycode}`}>  <FontAwesomeIcon icon={faEdit} /> </Button> 
                <div className="modal fade" id={`${item.agencycode}`} tabIndex="-1" aria-labelledby={`Label${item.agencycode}`} aria-hidden="true">
         <div className="modal-dialog"style={{maxWidth:'78rem'}}>
            <div className="modal-content">
               <div className="modal-header">
               <h5 className="modal-title text-primary" id={`Label${item.agencycode}`}>Update Contractor</h5>
               <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
               </div> 
               <form action="https://erp.smpinfra.in/smp/admin/updateagencymandays.php" method="POST">
                      <div className="modal-body"> <center>
                      <Row align="left" style={{padding:'10px'}}>
                <Col md={1}></Col><Col md={3} style={{verticalAlign:"sub"}}>Project Code:</Col><Col md={6}>
               <Form.Control type="text" id="scode" name="scode" value={`${item.sitecode}`} readOnly/>
                </Col>
                </Row> 
                      <Row align="left" style={{padding:'10px'}}>
                <Col md={1}></Col><Col md={3} style={{verticalAlign:"sub"}}>Contractor Code:</Col><Col md={6}>
               <Form.Control type="text" id="acode" name="acode" value={`${item.agencycode}`} readOnly/>
                </Col>
                </Row> 
                      <Row align="left" style={{padding:'10px'}}> 
                <Col md={1}></Col><Col md={3} style={{verticalAlign:"sub"}}>Contractor Name:</Col><Col md={6}>
                <Form.Control placeholder="Enter New Project Name" type="text" id="aname" name="aname" value={item.agencyname} readOnly required/>
                </Col>
                </Row> 
                <Row align="left" style={{padding:'10px'}}> 
                <Col md={1}></Col><Col md={3} style={{verticalAlign:"sub"}}>Type 1:</Col><Col md={6}>
                <Form.Control placeholder="Enter Type1" type="text" id="type1" name="type1"  value={inputValues[index]?.type1 || item.type1}  onChange={(e) => handleinputchange1(e, index)} />
                </Col>
                </Row> 
                <Row align="left" style={{padding:'10px'}}> 
                <Col md={1}></Col><Col md={3} style={{verticalAlign:"sub"}}>Type 2:</Col><Col md={6}>
                <Form.Control placeholder="Enter Type2" type="text" id="type2" name="type2"  value={inputValues[index]?.type2 || item.type2}  onChange={(e) => handleinputchange1(e, index)} />
                </Col>
                </Row>
                <Row align="left" style={{padding:'10px'}}> 
                <Col md={1}></Col><Col md={3} style={{verticalAlign:"sub"}}>Type 3:</Col><Col md={6}>
                <Form.Control placeholder="Enter Type3" type="text" id="type3" name="type3"  value={inputValues[index]?.type3 || item.type3}  onChange={(e) => handleinputchange1(e, index)} />
                </Col>
                </Row>
                <Row align="left" style={{padding:'10px'}}> 
                <Col md={1}></Col><Col md={3} style={{verticalAlign:"sub"}}>Type 4:</Col><Col md={6}>
                <Form.Control placeholder="Enter Type4" type="text" id="type4" name="type4"  value={inputValues[index]?.type4 || item.type4}  onChange={(e) => handleinputchange1(e, index)} />
                </Col>
                </Row>
                <Row align="left" style={{padding:'10px'}}> 
                <Col md={1}></Col><Col md={3} style={{verticalAlign:"sub"}}>Type 5:</Col><Col md={6}>
                <Form.Control placeholder="Enter Type5" type="text" id="type5" name="type5"  value={inputValues[index]?.type5 || item.type5}  onChange={(e) => handleinputchange1(e, index)} />
                </Col>
                </Row>
                <Row align="left" style={{padding:'10px'}}> 
                <Col md={1}></Col><Col md={3} style={{verticalAlign:"sub"}}>Type 6:</Col><Col md={6}>
                <Form.Control placeholder="Enter Type6" type="text" id="type6" name="type6"  value={inputValues[index]?.type6 || item.type6}  onChange={(e) => handleinputchange1(e, index)} />
                </Col>
                </Row>
                          <Button type="submit" variant="primary" style={{ backgroundColor: 'rgb(0, 189, 252)', width: '50%', height: '50px', borderRadius: '0px 0px 15px 15px', border: '1.5px solid white' }}>Update Contractor</Button>
                        </center>
                      </div>
                    </form>
            
               <div className="modal-footer"> 
               <p>Note:Date Must Be In DD-MM-YYYY Format&#160;&#160;
               <button type="button" className="btn btn-warning" data-bs-dismiss="modal">Close</button></p>
               </div>
                  </div> 
         </div>
         </div> 

                 </td>
                      <td>{index+1}</td>
                      <td>{item.sitecode}</td>
                      <td>{item.agencycode}</td>
                      <td>{item.agencyname}</td>
                      <td>{item.type1}</td>
                      <td>{item.type2}</td>
                      <td>{item.type3}</td>
                      <td>{item.type4}</td>
                      <td>{item.type5}</td>
                      <td>{item.type6}</td> 
                  </tr> 
                  </tbody>))}
                  </Table>) : (<div>
           <center><p>No Result</p></center><br/> 
                <Table striped bordered hover responsive>
                <thead>
                  <tr>
                  <th></th> 
                  <th></th> 
                    <th>Sr No.</th>
                    <th>Project Name</th>
                    <th>Agency Code</th>
                    <th>Agency Name</th>
                    <th>Type1</th> 
                    <th>Type2</th> 
                    <th>Type3</th> 
                    <th>Type4</th> 
                    <th>Type5</th> 
                    <th>Type6</th>  
                  </tr>
                </thead>  
                {sel.map((item1, index1) =>  ( <tbody><tr key={index1}>
                    <td> <form action="https://erp.smpinfra.in/smp/admin/deleteagemanday.php" method="POST">
                  <Form.Control type="text" id="ageid" name="ageid" value={`${item1.agencycode}`} hidden/> 
                <Button type="submit" variant="primary" style={{backgroundColor:'red',width:'100%',height:"50px",borderRadius:"0px 0px 15px 15px",border:'1.5px solid white'}} >  <FontAwesomeIcon icon={faTrash} /> </Button> 
                </form></td>
                <td> 
                <Button type="submit" variant="primary" style={{backgroundColor:'rgb(0, 189, 252)',width:'100%',height:"50px",borderRadius:"0px 0px 15px 15px",border:'1.5px solid white'}} data-bs-toggle="modal" data-bs-target={`#${item1.agencycode}`}>  <FontAwesomeIcon icon={faEdit} /> </Button> 
                <div className="modal fade" id={`${item1.agencycode}`} tabIndex="-1" aria-labelledby={`Label${item1.agencycode}`} aria-hidden="true">
         <div className="modal-dialog"style={{maxWidth:'78rem'}}>
            <div className="modal-content">
               <div className="modal-header">
               <h5 className="modal-title text-primary" id={`Label${item1.agencycode}`}>Update Contractor</h5>
               <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
               </div> 
               <form action="https://erp.smpinfra.in/smp/admin/updateagencymandays.php" method="POST">
                      <div className="modal-body"> <center>
                      <Row align="left" style={{padding:'10px'}}>
                <Col md={1}></Col><Col md={3} style={{verticalAlign:"sub"}}>Project Code:</Col><Col md={6}>
               <Form.Control type="text" id="scode" name="scode" value={`${item1.sitecode}`} readOnly/>
                </Col>
                </Row> 
                      <Row align="left" style={{padding:'10px'}}>
                <Col md={1}></Col><Col md={3} style={{verticalAlign:"sub"}}>Contractor Code:</Col><Col md={6}>
               <Form.Control type="text" id="acode" name="acode" value={`${item1.agencycode}`} readOnly/>
                </Col>
                </Row> 
                      <Row align="left" style={{padding:'10px'}}> 
                <Col md={1}></Col><Col md={3} style={{verticalAlign:"sub"}}>Contractor Name:</Col><Col md={6}>
                <Form.Control placeholder="Enter New Project Name" type="text" id="aname" name="aname" defaultValue={item1.agencyname} readOnly required/>
                </Col>
                </Row> 
                <Row align="left" style={{padding:'10px'}}> 
                <Col md={1}></Col><Col md={3} style={{verticalAlign:"sub"}}>Type 1:</Col><Col md={6}>
                <Form.Control placeholder="Enter Type1" type="text" id="type1" name="type1" defaultValue={item1.type1} />
                </Col>
                </Row> 
                <Row align="left" style={{padding:'10px'}}> 
                <Col md={1}></Col><Col md={3} style={{verticalAlign:"sub"}}>Type 2:</Col><Col md={6}>
                <Form.Control placeholder="Enter Type2" type="text" id="type2" name="type2" defaultValue={item1.type2} />
                </Col>
                </Row>
                <Row align="left" style={{padding:'10px'}}> 
                <Col md={1}></Col><Col md={3} style={{verticalAlign:"sub"}}>Type 3:</Col><Col md={6}>
                <Form.Control placeholder="Enter Type3" type="text" id="type3" name="type3" defaultValue={item1.type3} />
                </Col>
                </Row>
                <Row align="left" style={{padding:'10px'}}> 
                <Col md={1}></Col><Col md={3} style={{verticalAlign:"sub"}}>Type 4:</Col><Col md={6}>
                <Form.Control placeholder="Enter Type4" type="text" id="type4" name="type4" defaultValue={item1.type4} />
                </Col>
                </Row>
                <Row align="left" style={{padding:'10px'}}> 
                <Col md={1}></Col><Col md={3} style={{verticalAlign:"sub"}}>Type 5:</Col><Col md={6}>
                <Form.Control placeholder="Enter Type5" type="text" id="type5" name="type5" defaultValue={item1.type5} />
                </Col>
                </Row>
                <Row align="left" style={{padding:'10px'}}> 
                <Col md={1}></Col><Col md={3} style={{verticalAlign:"sub"}}>Type 6:</Col><Col md={6}>
                <Form.Control placeholder="Enter Type6" type="text" id="type6" name="type6" defaultValue={item1.type6} />
                </Col>
                </Row>
                          <Button type="submit" variant="primary" style={{ backgroundColor: 'rgb(0, 189, 252)', width: '50%', height: '50px', borderRadius: '0px 0px 15px 15px', border: '1.5px solid white' }}>Update Contractor</Button>
                        </center>
                      </div>
                    </form>
            
               <div className="modal-footer"> 
               <p>Note:Date Must Be In DD-MM-YYYY Format&#160;&#160;
               <button type="button" className="btn btn-warning" data-bs-dismiss="modal">Close</button></p>
               </div>
                  </div> 
         </div>
         </div> 

                 </td>
                    <td>{index1+1}</td>
                    <td>{item1.sitecode}</td>
                    <td>{item1.agencycode}</td>
                    <td>{item1.agencyname}</td>
                    <td>{item1.type1}</td>
                    <td>{item1.type2}</td>
                    <td>{item1.type3}</td>
                    <td>{item1.type4}</td>
                    <td>{item1.type5}</td>
                    <td>{item1.type6}</td> 
                </tr> 
                </tbody>))}
                </Table></div>)}
                </Container>
 </div>
);
}

export default Agencysel;